import { FC } from 'react'

type Props = {
  last_login?: Date
}

const UserLastLoginCell: FC<Props> = ({ last_login }) => (
  <div className='d-flex align-items-center'>
    {last_login ? (
      <div className='d-flex flex-column'>
        <div className='text-gray-700' style={{ fontSize: '12px' }}>
          <div>{new Date(last_login).toLocaleDateString('en-US', { timeZone: 'UTC' })}</div>
          <div>{new Date(last_login).toLocaleTimeString('en-US', { timeZone: 'UTC' })}</div>
        </div>
      </div>
    ) : ''}
  </div>
)

export { UserLastLoginCell }
