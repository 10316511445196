import React, { useState } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import { QAInterface1 } from "./QAInterface1";

const MyResearch = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState('');

    const handleFirstQuestionUpdate = (question) => {
        setCurrentQuestion(question);
        setIsLoading(false);
    };

    return (
        <div>
            <div className="d-flex flex-column px-5 py-0">
                <div className="d-flex flex-column-auto mb-2 mt-2">
                    <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="briefcase" iconType="duotone" className="fw-bold fs-1 mx-3 text-primary" />
                            <span>Lexim Workbench</span>
                            {isLoading && (
                                <div className="spinner-border text-primary ms-3" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                        </div>
                    </h1>
                </div>
            </div>

            <div className="container mt-3 px-8 p-3 text-wrap mx-auto vh-80 d-flex flex-row">
                <QAInterface1
                    onFirstQuestionAsked={handleFirstQuestionUpdate}
                    currentQuestion={currentQuestion}
                    className="col-12"
                />
            </div>
        </div>
    );
};

export default MyResearch;
