import React, { useState } from 'react';
import axios from 'axios';

const UpdateAnalytics: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleUpdateAnalytics = async () => {
        setLoading(true);
        setMessage('');
        try {
            await axios.post('/reg/v1/superadmin/update_fda_analytics'); // Replace with your actual API endpoint
            setMessage('Analytics are up to date');
        } catch (error) {
            setMessage('Failed to update analytics');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1 style={{ margin: 0 }}>Update Analytics</h1>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleUpdateAnalytics}
                    disabled={loading}
                    style={{
                        position: 'relative',
                        paddingRight: loading ? '2.5rem' : '1rem',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {loading ? (
                        <>
                            Please wait... 
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                style={{
                                    marginLeft: '10px',
                                    position: 'absolute',
                                    right: '10px',
                                }}
                            ></span>
                        </>
                    ) : (
                        'Update Analytics'
                    )}
                </button>
            </div>
            {message && (
                <div
                    style={{
                        marginTop: '10px',
                        padding: '10px',
                        border: `1px solid ${message.includes('Failed') ? 'red' : 'green'}`,
                        borderRadius: '5px',
                        backgroundColor: message.includes('Failed') ? '#ffe6e6' : '#e6ffe6',
                        color: message.includes('Failed') ? 'red' : 'green',
                    }}
                >
                    {message}
                </div>
            )}
        </div>
    );
};

export default UpdateAnalytics;
