import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { KTIcon } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';

// const API_URL = 'http://127.0.0.1:8000';

const UploadDocumentModal = ({ isOpen, onClose, setIsUploaded, setMessage, selectedFolder, folder_s3_link }) => {
    const [showModal, setShowModal] = useState(isOpen);
    const [loading, setLoading] = useState(false);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const checkbox1Ref = useRef<HTMLInputElement>(null);
    const checkbox2Ref = useRef<HTMLInputElement>(null);
    const checkbox3Ref = useRef<HTMLInputElement>(null);
    const checkbox4Ref = useRef<HTMLInputElement>(null);
    const checkbox5Ref = useRef<HTMLInputElement>(null);
    const checkbox6Ref = useRef<HTMLInputElement>(null);
    const [isChecked, setIsChecked] = useState(true);
    const [isChecked2, setIsChecked2] = useState(false);
    // const [isChecked3, setIsChecked3] = useState(false);
    const [alert, setAlert] = useState('');
    const { currentUser } = useAuth();
    const [fromLanguage, setFromLanguage] = useState('');
    const [toLanguage, setToLanguage] = useState('');
    const MAX_FILE_SIZE = 20 * 1024 * 1024;
    const MAX_FILES = 5;

    const uploadDocumentSchema = Yup.object().shape({
        document: Yup.mixed().required('An uploaded file is required.'),
        documentName: Yup.string().required('Document name is required.').max(100),
        description: Yup.string().max(500),
        // ...(isChecked2?  {
        //     translate_from: Yup.string().required('From language is required.'),
        //     translate_to: Yup.string().required('To language is required.')
        // }: {})
    });

    const closeModal = () => {
        setShowModal(false);
        onClose();
    }

    const handleUploadClick = async (values: any) => {
        const MAX_FILE_SIZE = 20 * 1024 * 1024;
        const MAX_FILES = 5;

        if (values.document.length > MAX_FILES) {
            setMessage('You can only upload up to 5 files.');
            setLoading(false);
            return;
        }

        values.document.forEach((file) => {
            if (file.size > MAX_FILE_SIZE) {
                setMessage('File size is greater than 20 MB. Please select a smaller file.');
                setLoading(false);
                return;
            }
        });

        // console.log(values.document);
        // console.log('values:', checkbox5Ref.current?.checked, fromLanguage, toLanguage);
        if (checkbox5Ref.current?.checked) {
            if (!fromLanguage || !toLanguage) {
                setMessage('Both "From" and "To" languages are required for translation.');
                return;
            }
        }
        console.log('values:', values);
        if (values.document) {
            setLoading(true);
            const formData = new FormData();
            const uploadFormData = new FormData();
            values.document.forEach((file) => {
                formData.append('file', file);
                uploadFormData.append('files', file);
            });
            formData.append('title', values.documentName);
            formData.append('description', values.description);
            formData.append('genai_requested', isChecked ? 'True' : 'False');
            formData.append('translation_requested', checkbox5Ref.current?.checked ? 'True' : 'False');
            formData.append('video_training_requested', checkbox6Ref.current?.checked ? 'True' : 'False');
            formData.append('translate_from', fromLanguage);
            formData.append('translate_to', toLanguage);
            formData.append('folder_id', selectedFolder ? String(Object.values(selectedFolder)[0]) : '');
            formData.append('folder_s3_link', folder_s3_link)

            uploadFormData.append('user_id', String(currentUser?.id));
            uploadFormData.append('vault_s3_link', folder_s3_link);

            const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/upload-file`, {
                method: 'POST',
                body: uploadFormData
            })

            // const response = await fetch(`https://deepapi.lexim.ai/upload-file`, {
            //     method: 'POST',
            //     body: uploadFormData
            // })

            if (response.ok) {

                const data = await response.json();

                // If data is an array, you need to use the first element of it
                const uploadedResults = JSON.parse(data[0].body);  // Access the first element in the array

                // Check if uploadedResults is defined before trying to iterate
                if (uploadedResults) {
                    uploadedResults.forEach(result => {
                        formData.append('mv_document_id_external', result.mv_document_id_external);
                        formData.append('s3_link', result.file_path);
                    });
                } else {
                    console.error('Uploaded results are missing or undefined');
                }

                axios.post(`/reg/v1/document/upload/${currentUser?.organization}/${currentUser?.id}`, formData)
                    .then(response => {
                        setLoading(false);
                        setMessage('Document uploaded successfully!')
                        // console.log(response);
                    })
                    .catch(error => {
                        setLoading(false);
                        if (error.response && error.response.status === 413) {
                            setMessage(`Failed to upload document due to size limit: File size is greater than 20 MB`);
                        } else {
                            setMessage(`Failed to upload documents: \n ${error.response?.data?.message || 'Unknown error'}`)
                            const failedMessages = error.response?.data?.filter((item: any) => item.status === "failed").map((item: any) => item.message).join('\n');
                            setMessage(`Failed to upload documents: \n ${failedMessages || 'Unknown error'}`);
                        }
                        console.error('There was an error!', error);

                    });
            }
            else {
                console.error('Failed to upload documents:', response);
                setMessage('Failed to upload documents: \n' + response.statusText);
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            document: null,
            documentName: '',
            description: '',
        },
        validationSchema: uploadDocumentSchema,
        onSubmit: async (values) => {
            setLoading(true);
            setAlert('');
            if (await formikRef.current.validateForm() && formikRef.current.isValid) {
                // if (await formik.validateForm() && formik.isValid) {
                try {
                    await handleUploadClick(values);
                    formikRef.current.resetForm();
                    setFileNames([]);
                    setIsUploaded(true);
                    closeModal();
                } catch (error) {
                    console.error('Failed to upload document:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        },
    });

    const formikRef = useRef(formik);
    formikRef.current = formik;
    useEffect(() => {
        setShowModal(isOpen);
        if (!isOpen) {
            // checkbox1Ref.current && (checkbox1Ref.current.checked = false);
            // checkbox2Ref.current && (checkbox2Ref.current.checked = false);
            // checkbox3Ref.current && (checkbox3Ref.current.checked = false);
            // checkbox4Ref.current && (checkbox4Ref.current.checked = false);
            // checkbox5Ref.current && (checkbox5Ref.current.checked = false);
            // checkbox6Ref.current && (checkbox6Ref.current.checked = false);
            // fileName && setFileName('');
            setFileNames([]);
            checkbox1Ref.current && (checkbox1Ref.current.checked = true);
            checkbox2Ref.current && (checkbox2Ref.current.checked = false);
            checkbox3Ref.current && (checkbox3Ref.current.checked = false);
            checkbox4Ref.current && (checkbox4Ref.current.checked = false);
            checkbox5Ref.current && (checkbox5Ref.current.checked = false);
            checkbox6Ref.current && (checkbox6Ref.current.checked = false);
            setIsChecked(true);
            setIsChecked2(false);
            // setIsChecked3(false);
            setFromLanguage('');
            setToLanguage('');
            formikRef.current.resetForm();
            // formik.resetForm();
        }
    }, [isOpen]);

    // console.log('folder_s3_link:', folder_s3_link);
    return (
        <>
            {/* <button type="button" className="btn btn-primary" onClick={openModal}>Support</button>
            <button type="button" className="btn btn-primary" onClick={openModal}>Feedback</button> */}
            <div
                className={`modal fade ${showModal ? 'show d-block' : 'd-none'}`}
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-750px' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* begin::Modal content */}
                    <div className='modal-content position-relative'>
                        {/* begin::Modal header */}
                        <div className='modal-header'>
                            {/* begin::Modal title */}
                            <h2 className='fw-bolder'>Upload Document to {selectedFolder && Object.keys(selectedFolder)[0] !== "All Documents" ? Object.keys(selectedFolder)[0] : "Root Folder"}</h2>
                            {/* end::Modal title */}
                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-organizations-modal-action='close'
                                onClick={closeModal}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTIcon iconName='cross' className='fs-1' />
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <form onSubmit={formik.handleSubmit} className="col-lg-12">
                            <div className='modal-body scroll-y' style={{ height: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                                <div className='card-body pt-0 pb-0  mx-5 mx-xl-15'>
                                    <div>
                                        <div className="row">
                                            <label htmlFor='document' className="col-3">
                                                <h4 className="required mt-3">Browse:</h4>
                                                <span>*File size &le; 20 MB, </span>
                                                <span>Maximum 5 files</span>
                                            </label>
                                            <div className="col-8 offset-1">
                                                <input
                                                    id='document'
                                                    name='document'
                                                    type='file'
                                                    multiple={true}
                                                    onChange={(event) => {
                                                        setLoading(true);
                                                        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
                                                            if (event.currentTarget.files.length > MAX_FILES) {
                                                                setAlert(`Maximum ${MAX_FILES} files are allowed.`);
                                                                setFileNames([]);
                                                                setLoading(false);
                                                                return;
                                                            }
                                                            const files = Array.from(event.currentTarget.files).slice(0, 5);
                                                            const filesExceedingLimit = files.filter(file => file.size > MAX_FILE_SIZE);
                                                            if (filesExceedingLimit.length > 0) {
                                                                setAlert('One or more file size exceeds the 20MB limit. Please select smaller files.');
                                                                setFileNames([]);
                                                                setLoading(false);
                                                                return;
                                                            }
                                                            // const file = event.currentTarget.files[0];
                                                            // if (file.size > MAX_FILE_SIZE) {
                                                            //     setAlert('File size exceeds the 20MB limit. Please select a smaller file.');
                                                            //     setFileName('');
                                                            //     setLoading(false);
                                                            //     return;
                                                            // }
                                                            setAlert('')
                                                            formik.setFieldValue('document', files);
                                                            formik.validateField('document');
                                                            setFileNames(files.map(file => file.name));
                                                        }
                                                        setLoading(false);
                                                    }}
                                                    className="form-control mb-10"
                                                    accept=".txt,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    required
                                                    style={{ position: 'absolute', left: '-9999px' }} // Hide the input off-screen
                                                />
                                                <button type="button" className="btn btn-primary" style={{ width: '35%' }} onClick={() => {
                                                    const inputElement = document.getElementById('document') as HTMLInputElement;
                                                    if (inputElement) {
                                                        inputElement.value = '';
                                                        inputElement.click();
                                                    }
                                                }}>
                                                    Browse
                                                </button>
                                                {alert !== '' ? (
                                                    <div className="text-danger mt-3 mb-5 col-12 text-start">{alert}</div>
                                                ) : formik.errors.document &&
                                                    formik.touched.document ? (
                                                    <div className="text-danger mt-3 mb-5 col-12 text-start">{formik.errors.document}</div>
                                                ) : (
                                                    // <p className="mt-3 mb-5">{fileName}</p>
                                                    <ul className="mt-3 mb-5">
                                                        {fileNames.map((name, index) => (
                                                            <li key={index}>{name}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <label htmlFor='documentName' className="col-3 mt-4">
                                                <h4 className="required">Title:</h4>
                                            </label>
                                            <div className="col-8 offset-1">
                                                <input
                                                    id='documentName'
                                                    name='documentName'
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    placeholder="Document Name"
                                                    className="form-control mt-3"
                                                    value={formik.values.documentName}
                                                    maxLength={100}
                                                    style={{ backgroundColor: '#f5f5f5' }}
                                                />
                                            </div>
                                        </div>
                                        <p className="mt-3 mb-6 col-12 text-end">{formik.values.documentName?.length || 0}/100</p>
                                        <div className="row">
                                            <label htmlFor='description' className="col-3">
                                                <h4>Description:</h4>
                                            </label>
                                            <div className="col-8 offset-1">
                                                <textarea
                                                    id='description'
                                                    name='description'
                                                    placeholder="Document Description"
                                                    className="form-control"
                                                    rows={7}
                                                    maxLength={500}
                                                    value={formik.values.description}
                                                    onChange={formik.handleChange}
                                                    style={{ resize: 'none', overflow: 'auto', backgroundColor: '#f5f5f5' }}
                                                />
                                            </div>
                                        </div>
                                        <p className="mt-3 mb-6 col-12 text-end">{formik.values.description?.length || 0}/500</p>
                                        <div className="row mt-3 mb-10">
                                            <label htmlFor='description' className="col-10">
                                                <h4>Generate:</h4>
                                            </label>
                                        </div>
                                        <div className="row offset-1" style={{ marginLeft: '10%' }}>
                                            <div className="col d-flex flex-row justify-content flex-wrap">
                                                <div className="d-flex flex-row justify-content flex-wrap">
                                                    <div className="form-check mb-6 d-flex">
                                                        <input ref={checkbox1Ref} className="form-check-input" type="checkbox" id="checkbox1" checked={isChecked} onChange={() => { setIsChecked(!isChecked); }} style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox1" style={{ fontSize: '1.2rem' }}>Summary, FAQ, Action Items, Impact</label>
                                                    </div>
                                                    {/* <div className="form-check mb-16 d-flex">
                                                        <input ref={checkbox2Ref} className="form-check-input mr-2" type="checkbox" id="checkbox2" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox2" style={{ fontSize: '1.2rem' }}>FAQ</label>
                                                    </div>
                                                    <div className="form-check mb-16 d-flex">
                                                        <input ref={checkbox3Ref} className="form-check-input mr-2" type="checkbox" id="checkbox3" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox3" style={{ fontSize: '1.2rem' }}>Action Items</label>
                                                    </div>
                                                    <div className="form-check mb-5 d-flex">
                                                        <input ref={checkbox4Ref} className="form-check-input mr-2" type="checkbox" id="checkbox4" style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox4" style={{ fontSize: '1.2rem' }}>Impact</label>
                                                    </div> */}
                                                </div>
                                                {/* </div> */}
                                                {/* <div className="col-1 d-flex align-items-center justify-content-center">
                                                    <div style={{ borderLeft: '1px solid #000', height: '110%' }}></div>
                                                </div> */}
                                                {/* <div className="col offset-1 d-flex flex-column align-items-start">  */}
                                                <div className="d-flex flex-row justify-content flex-wrap">
                                                    <div className="form-check mb-3 d-flex align-items-center">
                                                        <input ref={checkbox5Ref} className="form-check-input mr-2" type="checkbox" id="checkbox5" checked={isChecked2} onChange={() => { setIsChecked2(!isChecked2); }} style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark mb-1" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>Translation</label>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <label className="form-check-label ms-5 mb-4 text-dark" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>From:</label>
                                                        <div className="ms-5 mb-3">
                                                            <select className="form-select form-select-sm mb-2" value={fromLanguage}
                                                                onChange={(event) => {
                                                                    const selectedValue = event?.target.value;
                                                                    // console.log('selectedValue:', selectedValue);
                                                                    setFromLanguage(selectedValue);
                                                                }}
                                                                required={isChecked2}
                                                            >
                                                                <option value="">Select...</option>
                                                                <option value="Arabic">Arabic</option>
                                                                <option value="Czech">Czech</option>
                                                                <option value="German">German</option>
                                                                <option value="English">English</option>
                                                                <option value="Spanish">Spanish</option>
                                                                <option value="Finnish">Finnish</option>
                                                                <option value="French">French</option>
                                                                <option value="Hindi">Hindi</option>
                                                                <option value="Italian">Italian</option>
                                                                <option value="Japanese">Japanese</option>
                                                                <option value="Korean">Korean</option>
                                                                <option value="Dutch">Dutch</option>
                                                                <option value="Sinhala">Sinhala</option>
                                                                <option value="Chinese">Chinese</option>
                                                                <option value="Hebrew">Hebrew</option>
                                                                <option value="Portuguese">Portuguese</option>
                                                                <option value="Swedish">Swedish</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <label className="form-check-label ms-5 mb-4 text-dark" htmlFor="checkbox5" style={{ fontSize: '1.2rem' }}>To:</label>
                                                        <div className="ms-5 mb-3">
                                                            <select className="form-select form-select-sm mb-2" value={toLanguage}
                                                                onChange={(event) => {
                                                                    const selectedToValue = event?.target.value;
                                                                    // console.log('selectedToValue:', selectedToValue);
                                                                    setToLanguage(selectedToValue);
                                                                }}
                                                                required={isChecked2}
                                                            >
                                                                <option value="">Select...</option>
                                                                <option value="Arabic">Arabic</option>
                                                                <option value="Czech">Czech</option>
                                                                <option value="German">German</option>
                                                                <option value="English">English</option>
                                                                <option value="Spanish">Spanish</option>
                                                                <option value="Finnish">Finnish</option>
                                                                <option value="French">French</option>
                                                                <option value="Hindi">Hindi</option>
                                                                <option value="Italian">Italian</option>
                                                                <option value="Japanese">Japanese</option>
                                                                <option value="Korean">Korean</option>
                                                                <option value="Dutch">Dutch</option>
                                                                <option value="Sinhala">Sinhala</option>
                                                                <option value="Chinese">Chinese</option>
                                                                <option value="Hebrew">Hebrew</option>
                                                                <option value="Portuguese">Portuguese</option>
                                                                <option value="Swedish">Swedish</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="d-flex flex-row justify-content-center flex-wrap">
                                                    <div className="form-check mb-5 mt-3 d-flex align-items-center">
                                                        <input ref={checkbox6Ref} className="form-check-input mr-2" type="checkbox" id="checkbox6" checked={isChecked3} onChange={() => { setIsChecked3(!isChecked3); }} style={{ marginRight: '10px' }} />
                                                        <label className="form-check-label text-dark" htmlFor="checkbox6" style={{ fontSize: '1.2rem' }}>Video Training</label>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="my-10 mx-5 mx-xl-16">
                                <button
                                    type="button"
                                    className="btn btn-secondary col-3 offset-5"
                                    onClick={(e) => {
                                        closeModal();
                                        setFileNames([]);
                                        checkbox1Ref.current && (checkbox1Ref.current.checked = true);
                                        checkbox2Ref.current && (checkbox2Ref.current.checked = false);
                                        checkbox3Ref.current && (checkbox3Ref.current.checked = false);
                                        checkbox4Ref.current && (checkbox4Ref.current.checked = false);
                                        checkbox5Ref.current && (checkbox5Ref.current.checked = false);
                                        checkbox6Ref.current && (checkbox6Ref.current.checked = false);
                                        setIsChecked(true);
                                        setIsChecked2(false);
                                        // setIsChecked3(false);
                                        setFromLanguage('');
                                        setToLanguage('');
                                        formik.resetForm();
                                    }}
                                >
                                    Discard
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-success ms-10 col-3"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setAlert('');
                                        formik.handleSubmit(e as any);
                                    }}
                                >
                                    {loading ? 'Loading...' : 'Upload'}
                                </button>
                            </div>
                        </form>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div >
                {/* end::Modal dialog */}
            </div >
            {/* begin::Modal Backdrop */}
            {showModal && <div className='modal-backdrop fade show'></div>}
            {/* end::Modal Backdrop */}
        </>
    );
};

export default UploadDocumentModal;