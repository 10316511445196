import React, { FC } from 'react'
import MyDocuments from './MyDocumentsTable/MyDocuments'
// import { toAbsoluteUrl } from '../../../_metronic/helpers';

const MyDocumentsWrapper: FC = () => {
    return (
        <MyDocuments />

    )
}

export default MyDocumentsWrapper;