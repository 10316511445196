import { KTIcon } from '../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';

const DocDiffTableRow = ({ diffFile, uploadedFiles, setDiffDeleteConfirmation, setDiffToDelete }) => {
    const navigate = useNavigate();
    // console.log("uploaded files:", uploadedFiles);
    const findFileById = (files, id) => {
        for (const file of files) {
            if (file.id === id && file.type === 'file') {
                return file;
            }
            if (file.type === 'folder' && file.files) {
                const found = findFileById(file.files, id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const file1 = findFileById(uploadedFiles, diffFile.doc_id_1);
    const file2 = findFileById(uploadedFiles, diffFile.doc_id_2);

    const title1 = file1?.doc_name;
    const title2 = file2?.doc_name;
    const filename1 = file1?.name;
    const filename2 = file2?.name;

    const navigateToDiff = () => {
        navigate(`/doc-comparison/${title1}/${title2}`, {
            state: {
                filename1: filename1,
                filename2: filename2,
                diffFile: diffFile
            }
        });
    };

    // console.log(title1, title2, typeof(filename1), filename2);
    return (
        <tr onClick={navigateToDiff} style={{ cursor: 'pointer' }}>
            <td className="text-start">
                <span className='fw-bold'>File name: </span> {filename1}
                <br />
                <span className='fw-bold'>Title: </span> {title1}
            </td>
            <td className="text-start">
                <span className='fw-bold'>File name: </span>  {filename2}
                <br />
                <span className='fw-bold'>Title: </span> {title2}
            </td>
            <td className="text-start">
                {new Date(diffFile?.created_on).toLocaleString('en-US', { timeZone: 'UTC' })}
            </td>
            <td className="text-start">
                {diffFile.is_completed ?
                    <span className='badge badge-light-success fs-8 fw-bold'>Completed</span>
                    : <span className='badge badge-light-dark fs-8 fw-bold'>Pending</span>
                }
            </td>
            <td className="px-5">
                {/* {diffFile.s3_link} */}
                <button className="btn btn-primary btn-sm" onClick={navigateToDiff}>Details</button>
                {/* {isOpen && (
                    // compareOnClose, isCompleted, title1, filename1, title2, filename2, s3_link
                    <CompareDocumentModal
                        compareIsOpen={isOpen}
                        compareOnClose={compareOnClose}
                        isCompleted={diffFile.is_completed}
                        title1={title1}
                        filename1={filename1}
                        title2={title2}
                        filename2={filename2}
                        s3_link={diffFile.s3_link}
                        setActiveTab={setActiveTab}
                    />
                )} */}
            </td>
            <td>
                <button
                    type='button'
                    className='btn btn-icon btn-active-light-primary btn-sm me-4'
                    data-bs-toggle='tooltip'
                    title='Delete'
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDiffDeleteConfirmation(true);
                        setDiffToDelete(diffFile.diff_id); // Store the entire document object
                    }}
                >
                    <KTIcon iconName='trash' className='fs-2 duotone text-danger' />
                </button>
            </td>
        </tr>
    );
};

export default DocDiffTableRow;