// @ts-nocheck
import { Column } from 'react-table'
import { UserInfoCell } from './UserInfoCell'
import { UserLastLoginCell } from './UserLastLoginCell'
import { UserTwoStepsCell } from './UserTwoStepsCell'
import { UserActionsCell } from './UserActionsCell'
import { UserSelectionCell } from './UserSelectionCell'
import { UserCustomHeader } from './UserCustomHeader'
import { UserSelectionHeader } from './UserSelectionHeader'
import { User } from '../../core/_models'
import { UserOrganizationCell } from './UserOrganizationCell'
import { UserLockedCell } from './UserLockedCell'
import { UserActiveCell } from './UserActiveCell'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className=' min-w-300px ' />,
    id: 'first_name',
    Cell: ({ ...props }) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Organization' className=' min-w-150px ' />,
    id: 'org_name',
    Cell: ({ ...props }) => <UserOrganizationCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Role' className='min-w-100px' />
    ),
    id: 'role',
    Cell: ({ ...props }) => <UserTwoStepsCell role={props.data[props.row.index].role} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Last Login' className='min-w-125px ' />
    ),
    id: 'login_success_time',
    Cell: ({ ...props }) => <UserLastLoginCell last_login={props.data[props.row.index].login_success_time} />,
  },
  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
  //   accessor: 'role',
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px ' />
    ),
    id: 'is_active',
    Cell: ({ ...props }) => <UserActiveCell active={props.data[props.row.index].is_active} />,
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Locked' className='min-w-125px' />
    ),
    id: 'is_locked',
    Cell: ({ ...props }) => <UserLockedCell is_locked={props.data[props.row.index].is_locked} />,
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Notes' className='min-w-125px' />
  //   ),
  //   id: 'adminnotes',
  //   Cell: ({ ...props }) => <UserTwoStepsCell adminnotes={props.data[props.row.index].adminnotes} />,
  // },

  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />
  //   ),
  //   accessor: 'joined_day',
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px ' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => {
      const id = props.data[props.row.index].id;
      return <UserActionsCell id={id} is_active={props.data[props.row.index].is_active} is_locked={props.data[props.row.index].is_locked} />;
    },
  },
]

export { usersColumns }
