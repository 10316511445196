import React, { useState, useEffect, useRef } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import { QAInterface } from "./QAInterface";
import clsx from 'clsx';
import Title21Viewer from "./Title21Viewer";
import ReactDOM from 'react-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DownloadIcon = ({ onClick, sectionText }) => (
    <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip
                id="tooltip-download"
            >
                Download text from <br />
                <strong>{sectionText}</strong>
            </Tooltip>
        }
    >
        <i
            className="bi bi-download text-primary text-bold fs-2 ms-n1"
            style={{ cursor: 'pointer' }}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                onClick();
            }}
        />
    </OverlayTrigger>
);


function XmlFileViewer({ setIsLoading, searchInput, setSearchInput, expandAll, collapseAll, isLoading }) {
    const [content, setContent] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isResultsExpanded, setIsResultsExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isChatOpen] = useState(true);
    const [activeTab, setActiveTab] = useState('Document');
    const processedSummaries = useRef([]);

    const scrollToTopDocument = () => {
        const firstSummary = document.querySelector('summary');
        const initialScrollY = window.scrollY;

        if (firstSummary) {
            firstSummary.scrollIntoView({ behavior: 'smooth' });

            setTimeout(() => {
                if (window.scrollY > initialScrollY || window.scrollY > 1) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }, 5);
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const scrollToTopLeximQA = () => {
        const contentContainer = document.querySelector('.xml-file-viewer-container .card-body');
        const firstSummary = contentContainer ? contentContainer.querySelector('summary') : null;
        const initialScrollY = window.scrollY;

        if (firstSummary) {
            firstSummary.scrollIntoView({ behavior: 'smooth' });
            setTimeout(() => {
                if (window.scrollY > initialScrollY || window.scrollY > 1) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            }, 5);
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };


    useEffect(() => {
        fetch('/files/title_21_HIJ.html')
            .then(response => response.text())
            .then(htmlData => {
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(htmlData, 'text/html');

                // Open details elements up to the third level (part level)
                const detailsElements = htmlDoc.querySelectorAll('details');
                detailsElements.forEach(details => {
                    let level = 0;
                    let parent = details.parentElement;
                    while (parent) {
                        if (parent.tagName.toLowerCase() === 'details') {
                            level++;
                        }
                        parent = parent.parentElement;
                    }
                    if (level < 2) {
                        details.open = true;
                    }
                });

                // Remove h1 and info elements
                const h1Element = htmlDoc.querySelector('h1');
                if (h1Element) h1Element.remove();
                const infoElements = htmlDoc.querySelectorAll('.info');
                infoElements.forEach(el => el.remove());

                // Process summaries and add download button placeholders
                const summaryElements = htmlDoc.querySelectorAll('summary');
                summaryElements.forEach((summary, index) => {
                    if (!processedSummaries.current.includes(summary)) {
                        // Wrap text in a <span> to apply inline layout without affecting the arrow
                        const span = document.createElement('span');
                        span.style.display = 'inline'; // Set display to inline
                        span.style.marginRight = '0.5rem'; // Adjust as needed for spacing
                        span.textContent = summary.textContent.trim();
                        summary.textContent = ''; // Clear existing text
                        summary.appendChild(span);

                        // Create a download icon placeholder as an inline <span> element
                        const downloadPlaceholder = document.createElement('span');
                        downloadPlaceholder.className = 'download-placeholder';
                        downloadPlaceholder.style.display = 'inline'; // Set display to inline
                        downloadPlaceholder.style.cursor = 'pointer';
                        downloadPlaceholder.style.marginLeft = '0.5rem'; // Adjust for spacing from text
                        downloadPlaceholder.dataset.index = index;  // Add an index to identify later

                        summary.appendChild(downloadPlaceholder);
                        processedSummaries.current.push(summary);
                    }
                });

                const updatedContent = htmlDoc.documentElement.innerHTML;
                setContent(updatedContent);
                setIsLoading(false);
            })
            .catch(error => console.error('Error:', error));
    }, [setIsLoading]);

    useEffect(() => {
        if (content) {
            const placeholders = document.querySelectorAll('.download-placeholder');

            placeholders.forEach(placeholder => {
                const index = placeholder.dataset.index;
                const summary = processedSummaries.current[index];
                let sectionText = summary.textContent.trim();

                // Summary processing logic
                if (sectionText.includes('—')) {
                    sectionText = sectionText.split('—')[0].trim();
                } else {
                    sectionText = sectionText.split(' ').slice(0, 2).join(' ');
                    sectionText = sectionText.replace(/[.,/#!$%^&*;:{}=\-_`~()]$/, "");
                }

                if (!summary.textContent.trim().toLowerCase().includes("reserved")) {
                    ReactDOM.render(
                        <DownloadIcon
                            sectionText={sectionText}
                            onClick={() => {
                                const detailsElement = summary.closest('details');

                                const extractText = (node, level = 0) => {
                                    if (node.nodeType === Node.TEXT_NODE) {
                                        return ' '.repeat(level * 4) + node.textContent.trim() + '\n';
                                    } else if (node.nodeType === Node.ELEMENT_NODE) {
                                        if (node.classList.contains('gpotbl_div')) {
                                            // Format the table for text file
                                            const tableText = formatTable(node);
                                            return tableText + '\n';
                                        } else {
                                            return Array.from(node.childNodes)
                                                .map(child => extractText(child, level + 1))
                                                .join('');
                                        }
                                    }
                                    return '';
                                };

                                const formatTable = (tableDiv) => {
                                    let tableText = '';
                                    const rows = tableDiv.querySelectorAll('tr');

                                    // Step 1: Calculate max column widths, considering `colspan`
                                    const columnWidths = [];
                                    rows.forEach(row => {
                                        let colIndex = 0;
                                        const cells = row.querySelectorAll('th, td');

                                        cells.forEach(cell => {
                                            const cellText = cell.textContent.trim();
                                            const colspan = parseInt(cell.getAttribute('colspan') || '1');

                                            // Ensure columnWidths array has space for `colspan`
                                            while (columnWidths.length < colIndex + colspan) {
                                                columnWidths.push(0);
                                            }

                                            // Set or update width for each column spanned by this cell
                                            const width = cellText.length;
                                            for (let i = 0; i < colspan; i++) {
                                                columnWidths[colIndex + i] = Math.max(columnWidths[colIndex + i], width);
                                            }
                                            colIndex += colspan;
                                        });
                                    });

                                    // Adjust column widths for padding (adding 2 for padding on each side)
                                    const paddedColumnWidths = columnWidths.map(width => width + 2);

                                    // Helper function to generate separator line based on column widths
                                    const generateSeparatorLine = () => {
                                        return '-'.repeat(paddedColumnWidths.reduce((sum, width) => sum + width, 0) + paddedColumnWidths.length * 3 + 1);
                                    };

                                    const separatorLine = generateSeparatorLine();

                                    // Step 2: Add separator line at the beginning of the table
                                    tableText += separatorLine + '\n';

                                    // Step 3: Generate the first header row
                                    let headerRow1 = '';
                                    let colIndex = 0;

                                    rows[0].querySelectorAll('th').forEach((cell) => {
                                        const cellText = cell.textContent.trim();
                                        const colspan = parseInt(cell.getAttribute('colspan') || '1');
                                        const cellWidth = paddedColumnWidths.slice(colIndex, colIndex + colspan).reduce((a, b) => a + b, 0);

                                        headerRow1 += '| ' + cellText.padEnd(cellWidth, ' ') + ' ';
                                        colIndex += colspan;
                                    });

                                    // Close the first header row with a pipe
                                    headerRow1 += '|';
                                    tableText += headerRow1.padEnd(separatorLine.length - 1, ' ') + '\n'; // Align with separator

                                    // Step 4: Generate the second header row, adding "Accept" and "Reject" under "Number Defective"
                                    let headerRow2 = '';
                                    colIndex = 0;
                                    rows[0].querySelectorAll('th').forEach((cell) => {
                                        const colspan = parseInt(cell.getAttribute('colspan') || '1');

                                        if (colspan === 2) {  // Assuming "Number Defective" has colspan of 2
                                            const acceptCell = 'Accept'.padEnd(paddedColumnWidths[colIndex], ' ');
                                            const rejectCell = 'Reject'.padEnd(paddedColumnWidths[colIndex + 1], ' ');
                                            headerRow2 += `| ${acceptCell} | ${rejectCell} `;
                                            colIndex += 2;
                                        } else {
                                            const emptyCell = ''.padEnd(paddedColumnWidths[colIndex], ' ');
                                            headerRow2 += '| ' + emptyCell + ' ';
                                            colIndex += 1;
                                        }
                                    });

                                    // Close the second header row with a pipe
                                    headerRow2 += '|';
                                    tableText += headerRow2.padEnd(separatorLine.length - 1, ' ') + '\n'; // Align with separator

                                    // Step 5: Insert a separator line after the headers
                                    tableText += separatorLine + '\n';

                                    // Step 6: Format each data row
                                    rows.forEach((row, rowIndex) => {
                                        if (rowIndex === 0 || rowIndex === 1) return; // Skip both header rows

                                        let rowText = '';
                                        colIndex = 0;

                                        const cells = row.querySelectorAll('td');
                                        cells.forEach(cell => {
                                            const cellText = cell.textContent.trim();
                                            const cellWidth = paddedColumnWidths[colIndex];
                                            const cellContent = cellText.padEnd(cellWidth, ' ');

                                            rowText += '| ' + cellContent + ' ';
                                            colIndex += 1;
                                        });

                                        // Add final pipe at the end of each row
                                        rowText += '|';
                                        tableText += rowText.padEnd(separatorLine.length - 1, ' ') + '\n'; // Align with separator
                                    });

                                    // Step 7: Add separator line at the end of the table
                                    tableText += separatorLine + '\n';

                                    return tableText;
                                };


                                const formattedContent = extractText(detailsElement);
                                console.log('Formatted Content with Custom Table Formatting:\n', formattedContent);

                                const blob = new Blob([formattedContent], { type: 'text/plain' });
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = `CFR_Title21_${sectionText}.txt`;
                                a.click();
                                URL.revokeObjectURL(url);
                            }}
                        />,
                        placeholder
                    );
                }
            });
        }
    }, [content]);



    const getPath = (element) => {
        let path = [];
        while (element) {
            const summary = element.querySelector('summary');
            if (summary) {
                let summaryText = summary.textContent;
                if (element.querySelector('details')) { // if there is a child 'details' element
                    summaryText = summaryText.replace('—', ' ');
                    // Moved slicing logic outside the loop
                }
                path.unshift(summaryText);
            }
            element = element.parentElement.closest('details');
        }
        // Apply slicing logic to all elements except the last one
        path = path.map((text, index) => {
            if (index < path.length - 1) { // Check if it's not the last element
                let modifiedText = text.split(' ').filter(word => word !== "").slice(0, 2).join(' '); // keep only the first two words
                modifiedText = modifiedText.replace(/[.,/#!$%^&*;:{}=\-_`~()]$/, ""); // remove the last punctuation
                return modifiedText;
            }
            return text;
        });

        return path.reduce((prev, curr, i) => {
            return [...prev, i > 0 ? <i className="fas fa-arrow-right mx-2 text-dark" /> : null, curr];
        }, []);
    };

    useEffect(() => {
        setSearchInput('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab])

    useEffect(() => {
        if (searchInput && searchInput.length > 1) {
            setLoading(true);
            const timeoutId = setTimeout(() => {
                const allDetailsElements = document.querySelectorAll('details');
                const matchingElements = Array.from(allDetailsElements).filter(el => {
                    const summary = el.querySelector('summary');
                    const directTextContent = Array.from(el.childNodes).filter(node => node.nodeType === 3).map(node => node.textContent).join('');
                    if ((summary && summary.textContent.toLowerCase().includes(searchInput.toLowerCase())) || directTextContent.toLowerCase().includes(searchInput.toLowerCase())) {
                        return true;
                    }
                    return false;
                });
                const matchingPaths = matchingElements.map(getPath);
                const halfLength = Math.ceil(matchingPaths.length / 2);
                setSearchResults(matchingPaths.slice(0, halfLength));
                setIsResultsExpanded(true);
                setLoading(false);
            }, 500);
            return () => clearTimeout(timeoutId);
        }
    }, [searchInput, setIsLoading]);
    return (
        <div className='card card-custom'>
            <div className='card-header card-header-stretch'>
                <ul
                    className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent flex-nowrap'
                    role='tablist'
                >

                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'Document' })}
                            onClick={() => setActiveTab('Document')}
                            role='tab'
                        >
                            Document
                        </h5>
                    </li>
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'History' })}
                            onClick={() => setActiveTab('History')}
                            role='tab'
                        >
                            History
                        </h5>
                    </li>
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'LeximQ&A' })}
                            onClick={() => setActiveTab('LeximQ&A')}
                            role='tab'
                        >
                            Lexim Query
                        </h5>
                    </li>
                </ul>
                <div className="d-flex align-items-center ml-auto">
                    {activeTab === 'Document' && (
                        <button className="btn btn-sm btn-primary mt-2 me-5" onClick={scrollToTopDocument}>
                            <KTIcon iconName='arrow-up' className='fs-1' />
                            Scroll to Top
                        </button>
                    )}
                    {activeTab === 'LeximQ&A' && (
                        <button className="btn btn-sm btn-primary mt-2 me-5" onClick={scrollToTopLeximQA}>
                            <KTIcon iconName='arrow-up' className='fs-1' />
                            Scroll to Top
                        </button>
                    )}
                </div>
            </div>
            <div className='card-body px-0' >
                <div className='tab-content px-0'>
                    <div className={clsx('tab-pane', { active: activeTab === 'Document' })}>
                        <div>
                            {searchInput && <div className="d-flex align-items-center mb-4 justify-content-between">
                                <div className="d-flex align-items-center" style={{ visibility: searchInput ? 'visible' : 'hidden' }}>
                                    <h5 className="mt-2 mb-0 ms-5">
                                        Found {searchResults.length} matching results for: {" "}
                                        <span className="text-primary font-italic">{searchInput}</span>
                                    </h5>
                                    <button className="btn btn-sm btn-primary ms-4 mt-2" onClick={() => setIsResultsExpanded(!isResultsExpanded)}>
                                        {isResultsExpanded ? 'Hide Results' : 'Show Results'}
                                    </button>
                                    {loading && <div className="spinner-border text-primary ms-4 mt-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                </div>
                            </div>}
                            {searchInput && searchInput.length > 1 && isResultsExpanded &&
                                <div className="list-group list-group-flush mb-5" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                    {searchResults.map((result, index) => (
                                        < div key={`${result.join('')}-${index}`} className="list-group-item mb-3"  >
                                            <span style={{ color: '#000000' }}>{result.slice(0, -1)}</span>
                                            <span style={{ cursor: 'pointer', color: '#007bff' }} onClick={() => {
                                                // Find the corresponding details element
                                                const allDetailsElements = document.querySelectorAll('details');
                                                const detailsElement = Array.from(allDetailsElements).find(el => {
                                                    const path = getPath(el);
                                                    return JSON.stringify(path) === JSON.stringify(result);
                                                });
                                                const initialScrollY = window.scrollY;
                                                if (detailsElement) {
                                                    // Open the details element and all its parent details elements
                                                    let element = detailsElement;
                                                    while (element) {
                                                        element.open = true;
                                                        element = element.parentElement.closest('details');
                                                    }
                                                    // Scroll to the details element
                                                    detailsElement.scrollIntoView({ behavior: 'smooth' });
                                                    setTimeout(() => {
                                                        if (window.scrollY > initialScrollY || window.scrollY > 1) {
                                                            window.scrollTo({ top: 0, behavior: 'smooth' });
                                                        }
                                                    }, 10);
                                                }
                                            }}>
                                                {result.slice(-1)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className="container p-0 pe-20 text-wrap mx-0 xml-file-viewer-container w-100"
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    whiteSpace: 'pre-wrap',
                                    boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                    //paddingRight: '500px',
                                    scrollbarWidth: 'auto',
                                    maxHeight: isResultsExpanded ? 'calc(100vh - 380px)' : 'calc(100vh - 230px)',
                                    maxWidth: 'calc(100vw)',
                                    width: 'calc(100vw)'
                                }}
                            >

                                {content ?
                                    <div style={{ paddingLeft: 0 }} dangerouslySetInnerHTML={{ __html: content }} /> :
                                    <p></p>
                                }
                                {/* <QAInterface style={{ flex: 1 }} /> */}
                            </div>
                        </div >
                    </div>
                    <div className={clsx('tab-pane', { active: activeTab === 'LeximQ&A' })}>
                        <div>
                            {searchInput && <div className="d-flex align-items-center mb-4 justify-content-between">
                                <div className="d-flex align-items-center" style={{ visibility: searchInput ? 'visible' : 'hidden' }}>
                                    <h5 className="mt-2 mb-0 ms-5">
                                        Found {searchResults.length} matching results for: {" "}
                                        <span className="text-primary font-italic">{searchInput}</span>
                                    </h5>
                                    <button className="btn btn-sm btn-primary ms-4 mt-2" onClick={() => setIsResultsExpanded(!isResultsExpanded)}>
                                        {isResultsExpanded ? 'Hide Results' : 'Show Results'}
                                    </button>
                                    {loading && <div className="spinner-border text-primary ms-4 mt-2" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>}
                                </div>
                            </div>}
                            {searchInput && searchInput.length > 1 && isResultsExpanded &&
                                <div className="list-group list-group-flush mb-5" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                                    {searchResults.map((result, index) => (
                                        < div key={`${result.join('')}-${index}`} className="list-group-item mb-3"  >
                                            <span style={{ color: '#000000' }}>{result.slice(0, -1)}</span>
                                            <span style={{ cursor: 'pointer', color: '#007bff' }} onClick={() => {
                                                const contentContainer = document.querySelector('.xml-file-viewer-container .card-body');
                                                const initialScrollY = window.scrollY;
                                                if (!contentContainer) {
                                                    console.warn('Content container not found');
                                                    return;
                                                }
                                                const allDetailsElements = contentContainer.querySelectorAll('details');
                                                const detailsElement = Array.from(allDetailsElements).find(el => {
                                                    const path = getPath(el);
                                                    return JSON.stringify(path) === JSON.stringify(result);
                                                });
                                                if (detailsElement) {
                                                    // Open the details element and all its parent details elements
                                                    let element = detailsElement;
                                                    while (element) {
                                                        element.open = true;
                                                        element = element.parentElement.closest('details');
                                                    }
                                                    // Scroll to the details element
                                                    detailsElement.scrollIntoView({ behavior: 'smooth' });
                                                    setTimeout(() => {
                                                        if (window.scrollY > initialScrollY || window.scrollY > 1) {
                                                            window.scrollTo({ top: initialScrollY, behavior: 'smooth' });
                                                        }
                                                    }, 5);
                                                }
                                            }}>
                                                {result.slice(-1)}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className="container p-0 text-wrap mx-0 xml-file-viewer-container d-flex flex-row" style={{ maxWidth: 'calc(100vw - 350px)', width: 'calc(100vw - 350px)' }}>
                                <div className={`card-body text-wrap ${isChatOpen ? 'col-7' : 'col-12'}`}
                                    style={{
                                        overflowY: 'auto',
                                        padding: 0,
                                        overflowX: 'hidden',
                                        whiteSpace: 'pre-wrap',
                                        boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                        paddingRight: '50px',
                                        maxHeight: isResultsExpanded ? 'calc(100vh - 380px)' : 'calc(100vh - 230px)',
                                        flexBasis: '70%', // Fixed portion to prevent collapse
                                        minWidth: '300px' // Minimum width to prevent excessive shrinkage
                                    }}
                                >
                                    {content ?
                                        <div dangerouslySetInnerHTML={{ __html: content }} /> :
                                        <p></p>
                                    }
                                </div>
                                <div className="col-5">
                                    <QAInterface />

                                </div>
                            </div>
                        </div >
                    </div>
                    <div className="d-flex align-items-left justify-content-start px-5">
                        <h5 className="mt-2">
                            {(activeTab === 'History') && <Title21Viewer searchInput={searchInput} />}
                        </h5>
                    </div>

                </div >
            </div>
        </div >
    );
}

export default XmlFileViewer;
