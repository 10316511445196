import React, { useState, useEffect } from 'react';
import { KTIcon } from '../../_metronic/helpers';

const SearchBar = ({ searchInput, setSearchInput, placeholder }) => {
    const [tempSearchInput, setTempSearchInput] = useState(searchInput);

    // Effect to handle clearing the input
    useEffect(() => {
        if (tempSearchInput === '') {
            setSearchInput('');
        }
    }, [tempSearchInput, setSearchInput]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            setSearchInput(tempSearchInput);
        }
    };

    return (
        <div className="flex-column-auto align-items-center">
            <div className="d-flex align-items-center position-relative my-1">
                <KTIcon iconName="magnifier" className="fs-2 position-absolute ms-6" />
                <input
                    type="search"
                    id="form1"
                    className="form-control form-control-solid w-100 ps-14 border border-1"
                    placeholder={placeholder}
                    aria-label={placeholder}
                    value={tempSearchInput}
                    onChange={(e) => setTempSearchInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    maxLength={200}
                    style={{ backgroundColor: '#f1faff' }}
                />
            </div>
        </div>
    );
};

export default SearchBar;
