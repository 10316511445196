import React, { useEffect, useState } from 'react'

function QASuggestions({setQuestion, askQuestion, suggestedAnswers}) {
    const [triggerQuestion, setTriggerQuestion] = useState(false);
    
    useEffect(() => {
        if(triggerQuestion){
            askQuestion();
            setTriggerQuestion(false);
        }
    }, [askQuestion, triggerQuestion])
    
    const onClickHandler = (thisQuestion) => {
        if (suggestedAnswers && suggestedAnswers[thisQuestion]) {
            setQuestion(suggestedAnswers[thisQuestion]);
            setTriggerQuestion(true);
        }
    }

    return (
        <div className="d-flex justify-content-start flex-wrap mb-5 mt-0">
            {Object.keys(suggestedAnswers).map((key, index) => (
                <div key={index} className='me-2'>
                    <button 
                        className="btn btn-sm btn-primary mt-2" 
                        onClick={() => onClickHandler(key)}
                    >
                        {key}
                    </button>
                </div>
            ))}
        </div>
    );
}

export default QASuggestions;