import React from 'react';
import { Modal } from 'react-bootstrap';

const CreateFolderModal = ({
    showModal,
    setShowModal,
    handleNewFolder,
    isLoading,
}) => {
    const [folderName, setFolderName] = React.useState('');

    const closeModal = () => {
        setShowModal(false);
        setFolderName('');
    };

    const handleCreate = () => {
        handleNewFolder(folderName);
        closeModal();
    };

    return (
        <Modal show={showModal} onHide={closeModal} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>Create New Folder</Modal.Title>
            </Modal.Header>

            <div className="form-group px-10 py-7">
                <input
                    type="text"
                    className="form-control"
                    id="folderName"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    placeholder="Folder Name"
                />
            </div>

            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={closeModal}>
                    Close
                </button>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading || folderName === ''}
                    onClick={handleCreate}
                >
                    {!isLoading ? 'Create Folder' : (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateFolderModal;
