import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const MoveFolderModal = ({
    showModal,
    setShowModal,
    moveFile,
    listFolders,
    handleMoveFolder,
    isLoading
}) => {
    const [moveFolderID, setMoveFolderID] = useState(moveFile.parent_id ? moveFile.parent_id : 0);

    useEffect(() => {
        setMoveFolderID(moveFile.parent_id ? moveFile.parent_id : 0);
    }, [moveFile]);

    const closeModal = () => {
        setShowModal(false);
        setMoveFolderID(moveFile.parent_id ? moveFile.parent_id : 0);
    };

    return (
        <Modal show={showModal} onHide={closeModal} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton>
                <Modal.Title>
                    Move <span className="fw-semibold">{moveFile.name}</span> to another Folder
                </Modal.Title>
            </Modal.Header>

            {listFolders && (
                <div className="form-group px-10 py-7">
                    <select
                        className="form-select"
                        id="folder"
                        value={moveFolderID as number}
                        onChange={(e) => setMoveFolderID(Number(e.target.value))}
                    >
                        <option value={0}>Root Folder</option>
                        {Object.entries(listFolders).map(([folderName, folderId]) => (
                            folderName !== moveFile.name && (
                                <option key={folderName} value={folderId as number}>
                                    {folderName}
                                </option>
                            )
                        ))}
                    </select>
                </div>
            )}

            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                >
                    Close
                </button>

                <button
                    type="button"
                    className="btn btn-primary"
                    disabled={isLoading || (moveFile.parent_id !== null ? moveFolderID === moveFile.parent_id : moveFolderID === 0)}
                    onClick={() => {
                        handleMoveFolder(moveFolderID, moveFile);
                        closeModal();
                    }}
                >
                    {!isLoading ? `Move ${moveFile.type === "folder" ? 'Folder' : 'File'}` : (
                        <span className="indicator-progress" style={{ display: 'block' }}>
                            Please wait...{' '}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                    )}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default MoveFolderModal;
