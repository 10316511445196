import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const FolderSuccessModal = ({ showModal, setShowModal, modalMessage }) => {
    const handleClose = () => {
        setShowModal(false);
    };

    const isSuccess = modalMessage.includes('successfully!');

    return (
        <Modal show={showModal} onHide={handleClose} dialogClassName="modal-dialog-centered">
            <Modal.Header closeButton />
            <Modal.Body className="d-flex justify-content-center fs-2 mx-2">
                {isSuccess ? (
                    <div className="text-center">
                        <i className="bi bi-check-circle-fill text-success fs-1 me-3"></i>
                        {modalMessage.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </div>
                ) : (
                    <div className="text-center">
                        <i className="bi bi-x-circle text-danger fs-1 me-3"></i>
                        {modalMessage.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FolderSuccessModal;
