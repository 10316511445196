import { FC } from 'react'

type Props = {
  active?: number
}

const UserActiveCell: FC<Props> = ({ active }) => (
  <div className={`badge ${active === 1 ? 'badge-success' : 'badge-light'} fw-bolder`}>
    {active === 1 ? 'Active' : 'Inactive'}
  </div>)

export { UserActiveCell }
