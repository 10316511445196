// MyDocumentsToolbar.tsx
import React from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import SearchBar from '../../../components/SearchBar';
import DocumentFilter from './DocumentFilter';
import UploadDocumentModal from '../Modals/UploadDocumentModal';
import CreateFolderModal from '../Modals/CreateFolderModal';
import FolderSuccessModal from '../Modals/FolderSuccessModal';

const MyDocumentsToolbar = ({
    isLoading,
    handleOpenModal,
    isModalOpen,
    handleCloseModal,
    setIsUploaded,
    searchInput,
    setSearchInput,
    filters,
    handleFilterChange,
    handleApplyFilters,
    handleResetFilters,
    setMessage,
    handleNewFolder,
    selectedFolder,
    setShowCreateSuccessModal,
    showCreateSuccessModal,
    modalMessage,
    selectedFolderLink
}) => {
    const [showModal, setShowModal] = React.useState(false);

    return (
        <div className="d-flex flex-wrap gap-3 flex-row-fluid align-items-center px-8 mt-3 mb-7">
            <div className="col-6">
                <SearchBar
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    placeholder={`Search ${selectedFolder ? Object.keys(selectedFolder)[0] : "My Documents"}`}
                />
            </div>
            <div className="d-flex flex-column-auto ms-3 d-flex align-items-center ml-auto">
                <button
                    type='button'
                    className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                >
                    <KTIcon iconName='filter' className='fs-2' /> Filter
                </button>
                <DocumentFilter
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onApplyFilters={handleApplyFilters}
                    onResetFilters={handleResetFilters}
                />
            </div>
            <div className="ms-3 d-flex flex-column-auto d-flex align-items-center py-1">
                <button
                    type='button'
                    className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                    onClick={handleOpenModal}
                >
                    <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Upload Documents
                </button>
                <UploadDocumentModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    setIsUploaded={setIsUploaded}
                    setMessage={setMessage}
                    selectedFolder={selectedFolder}
                    folder_s3_link={selectedFolderLink}
                />
            </div>
            <div className="ms-3 d-flex flex-column-auto d-flex align-items-center py-1">
                <button
                    type='button'
                    className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                    onClick={() => setShowModal(true)}
                >
                    <KTIcon iconName='add-folder' iconType="solid" className='fs-2' /> Create Folder
                </button>
                <CreateFolderModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    handleNewFolder={handleNewFolder}
                    isLoading={isLoading}
                />
                <FolderSuccessModal
                    showModal={showCreateSuccessModal}
                    setShowModal={setShowCreateSuccessModal}
                    modalMessage={modalMessage}
                />
            </div>
        </div >
    );
};

export default MyDocumentsToolbar;