import { FC, useState } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'
import ReactMarkdown from 'react-markdown';
interface QAInterfaceProps {
    documentId: string;
    subtype: string;
    documentType: string;

}
interface QAPair {
    question: string;
    answer: string;
    error: boolean;
}
const QAInterface: FC<QAInterfaceProps> = ({ documentId, subtype, documentType }) => {
    const [question, setQuestion] = useState<string>('')
    const [qaPairs, setQAPairs] = useState<QAPair[]>([])
    // console.log(typeof documentId)


    const askQuestion = async () => {
        const currentQuestion = question;
        // Add the question to qaPairs with a placeholder answer
        setQAPairs(prevQAPairs => [{ question: currentQuestion, answer: 'Thinking ...', error: false }, ...prevQAPairs])
        setQuestion('')
        try {
            const response = await fetch(`${process.env.REACT_APP_DEEP_API_URL as string}/answer-query/${subtype}/${documentType}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: currentQuestion, document_id: documentId.toString(), sub_type: subtype, document_type: documentType })
            })

            if (!response.body) {
                throw new Error('No response body');
            }

            const reader = response.body.getReader();
            let text = '';

            const readStream = async () => {
                const { value, done } = await reader.read();

                if (done) {
                    return;
                }

                text += new TextDecoder().decode(value);

                // Update the placeholder answer with the current text
                setQAPairs(prevQAPairs => {
                    const newQAPairs = [...prevQAPairs]
                    newQAPairs[0].answer = text;
                    return newQAPairs
                })

                // Recursively read the next chunk
                await readStream();
            }

            await readStream();

            setQuestion('')
        } catch (error) {
            // Update the placeholder answer with the error message
            setQAPairs(prevQAPairs => {
                const newQAPairs = [...prevQAPairs]
                newQAPairs[0].answer = (error as Error).message
                newQAPairs[0].error = true
                return newQAPairs
            })
        }
    }

    return (
        <div className='card-body mt-5 ms-2 me-3 ' style={{ maxHeight: '85vh' }}>
            <div className='d-flex position-relative mb-2  '>
                <textarea
                    className="form-control form-control-lg pt-3 pb-2  "
                    placeholder="Please ask a question"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && question.trim() !== '') {
                            askQuestion();
                            e.preventDefault(); // Prevent form submission
                        }
                    }}
                    style={{ paddingRight: '35px', resize: 'none' }} // Make room for the icon and disable resizing
                    rows={1} // Start with one row
                />
                {(question.trim() !== '') && (
                    <button
                        onClick={askQuestion}
                        className='position-absolute end-0 top-50 translate-middle-y '
                        style={{ background: 'none', border: 'none' }}
                        disabled={question.trim() === ''}>
                        <KTIcon
                            iconName='arrow-right'
                            iconType="duotone"
                            className='fw-bold fs-1 ms-2'

                        />
                    </button>
                )}
            </div>
            <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>

                {qaPairs.map((qaPair, index) => (
                    <div key={index} className='d-flex flex-column mt-3'>
                        <div className='d-flex justify-content-start w-100 mt-2 mb-2'>
                            <div className={`p-4 fs-6 text-gray-900 fw-medium rounded ${qaPair.error ? 'bg-danger text-dark' : 'bg-light-info '}`} style={{ maxWidth: '80%', wordWrap: 'break-word' }}>
                                {qaPair.answer === 'Thinking ...' ?
                                    <>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="spinner-grow spinner-grow-sm text-primary ms-2" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </>

                                    :
                                    <ReactMarkdown>{qaPair.answer}</ReactMarkdown>}

                            </div>
                        </div>
                        <div className='d-flex justify-content-end w-100 mt-2 mb-2 '>

                            <div className='p-4  bg-light-primary text-gray-900 fw-medium  fs-6 rounded ' style={{ maxWidth: '80%', wordWrap: 'break-word' }}>
                                {qaPair.question}

                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export { QAInterface }