import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
//import { useAuth } from '../../../modules/auth';


const UploadWarningLetters: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [alert, setAlert] = useState('');
    const [jsonData, setJsonData] = useState<any[]>([]);
    const [showTable, setShowTable] = useState(false);
    //const { currentUser } = useAuth();
    const MAX_FILE_SIZE = 20 * 1024 * 1024;
    const MAX_FILES = 5;

    const uploadWarningLettersSchema = Yup.object().shape({
        document: Yup.mixed().required('An uploaded file is required.'),
        documentName: Yup.string().required('Document name is required.').max(100),
    });

    const handleUploadClick = async (values: { document: File[], documentName: string }) => {
        if (values.document.length > MAX_FILES) {
            setAlert('You can only upload up to 5 files.');
            setLoading(false);
            return;
        }

        for (const file of values.document) {
            if (file.size > MAX_FILE_SIZE) {
                setAlert('File size is greater than 20 MB. Please select a smaller file.');
                setLoading(false);
                return;
            }
        }

        if (values.document) {
            setLoading(true);
            const formData = new FormData();
            values.document.forEach((file: File) => {
                formData.append('file', file);
            });
            formData.append('title', values.documentName);

            try {
                console.log('Making POST request to /reg/v1/superadmin/warning_letter_upload');
                const response = await axios.post(`/reg/v1/superadmin/warning_letter_upload`, formData);
                if (response.status === 200) {
                    setAlert('Document uploaded to stagging successfully!');
                    await fetchJsonData(); // Fetch JSON data after successful upload
                }
            } catch (error) {
                if ((error as any).response && (error as any).response.status === 413) {
                    setAlert('Failed to upload document due to size limit: File size is greater than 20 MB');
                } else {
                    setAlert(`Failed to upload document: ${(error as any).response.data.message}`);
                }
                console.error('There was an error!', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const fetchJsonData = async () => {
        setLoading(true);
        try {
            console.log('Making GET request to /reg/v1/superadmin/warning_letter_upload');
            const response = await axios.get(`/reg/v1/superadmin/warning_letter_upload`);
            setJsonData(response.data);
            setShowTable(true);
        } catch (error) {
            setAlert('Failed to fetch JSON data.');
            console.error('There was an error!', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = async () => {
        setLoading(true);
        try {
            console.log('Making DELETE request to /reg/v1/superadmin/warning_letters_commit');
            await axios.delete(`/reg/v1/superadmin/warning_letters_commit`);
            setAlert('Upload cancelled successfully.');
            setShowTable(false);
            setFileNames([]);
        } catch (error) {
            setAlert('Failed to cancel upload.');
            console.error('There was an error!', error);
        } finally {
            setLoading(false);
        }
    };

    const handleProceed = async () => {
        setLoading(true);
        try {
            console.log('Making POST request to /reg/v1/superadmin/warning_letters_commit');
            await axios.post(`/reg/v1/superadmin/warning_letters_commit`);
            setAlert('Upload committed successfully.');
            setShowTable(false);
            setFileNames([]);
        } catch (error) {
            setAlert('Failed to commit upload.');
            console.error('There was an error!', error);
        } finally {
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues: {
            document: [],
            documentName: '',
        },
        validationSchema: uploadWarningLettersSchema,
        onSubmit: async (values) => {
            console.log('Form submitted with values:', values);
            setLoading(true);
            setAlert('');
            try {
                await handleUploadClick(values);
                formik.resetForm();
                setFileNames([]);
            } catch (error) {
                console.error('Failed to upload document:', error);
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className="row">
                <div
                    className='col-sm card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_warning_letters'
                    aria-expanded='true'
                    aria-controls='kt_warning_letters'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0 me-3'>Upload Warning Letters</h3>
                        {loading && <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </div>
                </div>
            </div>
            <div id='kt_warning_letters' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-2 col-form-label fw-bold fs-6'>
                                <span className=''>Browse:</span>
                            </label>
                            <div className='col-lg-9 fv-row'>
                                <input
                                    id='document'
                                    name='document'
                                    type='file'
                                    multiple={true}
                                    onChange={async (event) => {
                                        setLoading(true);
                                        if (event.currentTarget.files && event.currentTarget.files.length > 0) {
                                            if (event.currentTarget.files.length > MAX_FILES) {
                                                setAlert(`Maximum ${MAX_FILES} files are allowed.`);
                                                setFileNames([]);
                                                setLoading(false);
                                                return;
                                            }
                                            const files = Array.from(event.currentTarget.files).slice(0, 5);
                                            const filesExceedingLimit = files.filter(file => file.size > MAX_FILE_SIZE);
                                            if (filesExceedingLimit.length > 0) {
                                                setAlert('One or more file size exceeds the 20MB limit. Please select smaller files.');
                                                setFileNames([]);
                                                setLoading(false);
                                                return;
                                            }
                                            setAlert('');
                                            console.log('Selected files:', files);

                                            await formik.setFieldValue('document', files);
                                            await formik.setFieldValue('documentName', files[0].name);

                                            const updatedValues = {
                                                ...formik.values,
                                                document: files,
                                                documentName: files[0].name,

                                            }

                                            // formik.setFieldValue('document', files);
                                            formik.validateField('document');
                                            setFileNames(files.map(file => file.name));
                                            handleUploadClick(updatedValues);
                                        }
                                        setLoading(false);
                                    }}
                                    className="form-control mb-10"
                                    accept=".csv"
                                    required
                                />
                                {alert !== '' ? (
                                    <div className="text-danger mt-3 mb-5 col-12 text-start">{alert}</div>
                                ) : formik.errors.document && formik.touched.document ? (
                                    <div className="text-danger mt-3 mb-5 col-12 text-start">{formik.errors.document}</div>
                                ) : (
                                    <ul className="mt-3 mb-5">
                                        {fileNames.map((name, index) => (
                                            <li key={index}>{name}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    {showTable && (
                        <div className='card-body border-top p-9'>
                            <h4>Preview</h4>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        {Object.keys(jsonData[0] || {}).map((key) => (
                                            <th key={key}>{key}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {jsonData.map((item, index) => (
                                        <tr key={index}>
                                            {Object.values(item).map((value, i) => (
                                                <td key={i}>{String(value)}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='d-flex justify-content-end'>
                                <button
                                    type='button'
                                    className='btn btn-secondary me-2'
                                    onClick={async () => {
                                        await handleCancel();
                                        formik.resetForm();
                                        setFileNames([]);
                                        (document.getElementById('document') as HTMLInputElement).value = '';
                                    }}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={async () => {
                                        await handleProceed();
                                        formik.resetForm();
                                        setFileNames([]);
                                        (document.getElementById('document') as HTMLInputElement).value = '';
                                    }}
                                    disabled={loading}
                                >
                                    {!loading && 'Proceed to Upload'}
                                    {loading && (
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export { UploadWarningLetters };