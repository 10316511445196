import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';
import { useAuth } from '../modules/auth';

function DocumentListWrapper({ filteredDocuments, topRow = '', secondaryRow = [], thirdRow = [], bottomRow = [], summaryRow = '', linkTo, links = [], isInternational = false, state = {} }) {
    const [trackedDocuments, setTrackedDocuments] = useState([]);
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    const fetchInTrackedDocuments = useCallback(async () => {

        try {
            const response = await axios.get(`/reg/v1/document/user/international/${currentUser?.id}`);
            setTrackedDocuments(response.data);
            return response.data;

        } catch (error) {
            console.log('Failed to fetch tracked documents', error);
            return [];
        }
    }, [currentUser?.id]);

    const handleViewButtonPDFClick = (documentId) => {
        const url = `/viewpdf?id=${documentId}`
        if (isInternational) {
            sessionStorage.setItem('isInternational', true);
        }
        else {
            sessionStorage.setItem('isInternational', false);
        }
        window.open(url, '_blank')
    };

    const handleDownloadButtonPDFClick = async (documentId) => {
        try {
            const response = await axios.get(`/reg/v1/document/international/viewpdfa/${documentId}`);
            const presignedUrl = response.data.url;
            return presignedUrl;
        } catch (error) {
            console.error('Error downloading PDF:', error);
            return null;
        }
    };

    const handleTrackInDocument = (id) => {
        // setLoading(true);
        axios.post(`/reg/v1/document/international/track/${currentUser?.id}/${id}`)
            .then((response) => {
                //setAlert({ message: response.data.message, type: 'success' });
                // fetchTrackedDocuments(); // Fetch tracked documents again after tracking a new document
                setTrackedDocuments(prevState => {

                    const newState = prevState.map(doc => {
                        if (doc.iddocument === id) {
                            // console.log('Toggling track for document:', doc);
                            return { ...doc, track: doc.track === 1 ? 0 : 1 };
                        } else {
                            return doc;
                        }
                    });
                    return newState;
                });

                fetchInTrackedDocuments();

                // setLoading(false);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    logout();
                    navigate('/auth/login');
                } else {
                    console.error('Error tracking document:', error);
                }
                // setLoading(false);
            });
    }

    useEffect(() => {
        if (isInternational) {
            fetchInTrackedDocuments();
        }
    }, [isInternational, fetchInTrackedDocuments]);

    const renderRow = (document, row, keyColor, valueColor) => (
        <div className="d-flex align-items-center">
            {row.map(([label, key, func], idx) => {
                const value = func ? func(document[key]) : document[key];

                // Skip rendering if the value is null, undefined, or an empty string
                if (value === null || value === '') {
                    return null;
                }

                return (
                    <React.Fragment key={key}>
                        <h5 className="card-title pb-2" style={{ color: keyColor }}>
                            {label}: <span className="" style={{ color: valueColor }}>{value}</span>
                        </h5>
                        {idx < row.length - 1 && <span className="card-title pb-2 px-2" style={{ color: keyColor }}>|</span>}
                    </React.Fragment>
                );
            })}
        </div>
    );
    return (
        <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
            <table className="table">
                <tbody>
                    {filteredDocuments.map((document, index) => (
                        <tr key={index}>
                            <td colSpan="6">
                                <div className="card card-custom card-flush" style={{ borderTop: `6px solid ${index % 2 === 0 ? '#0088cc' : '#00aaff'}` }}>
                                    <div className="card-header d-flex justify-content-between align-items-start mx-4" style={{ padding: '1rem' }}>
                                        <h5 className="card-title mt-5 mb-3 m-0 col-9" style={{ flexGrow: 1 }}>
                                            {document.is_genai_exists === 1 && (
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={<Tooltip id="genai-tooltip">AI features for this document are available</Tooltip>}
                                                >
                                                    {({ ref, ...triggerHandler }) => (
                                                        <div
                                                            {...triggerHandler}
                                                            className="me-2"
                                                        >
                                                            {/* <KTIcon iconName='virus' iconType="solid" className='fs-2x me-3 text-success' /> */}
                                                            <i ref={ref} className="bi  bi-patch-check-fill fs-1 fw-bold text-success"></i>
                                                        </div>
                                                    )}
                                                </OverlayTrigger>
                                            )}
                                            <Link
                                                to={isInternational ? `/international/${document[linkTo]}` : `/document/${document[linkTo]}`}
                                                state={state}
                                                className="text-decoration-underline text-wrap"
                                                style={{
                                                    color: '#0063cc',
                                                    lineHeight: '1.2',
                                                    wordBreak: 'break-word',
                                                    overflowWrap: 'break-word',
                                                    display: 'inline-block',
                                                }}
                                            >
                                                {document[topRow]}
                                            </Link>
                                        </h5>
                                        <div className="card-toolbar col-3 d-flex justify-content-end align-items-center">
                                            {(document[links[0]]) && (document[links[0]] !== '') ? (
                                                <a href={document[links[0]]} className="btn h-30px ml-0 fw-bold btn-sm d-flex align-items-center justify-content-center text-primary" style={{ paddingLeft: 0 }}>
                                                    Download
                                                </a>
                                            ) : (
                                                <button
                                                    onClick={async (e) => {
                                                        e.preventDefault(); // Prevent the default link behavior
                                                        const url = await handleDownloadButtonPDFClick(document[links[1]]);
                                                        if (url) {
                                                            window.location.href = url; // Navigate to the pre-signed URL
                                                        }
                                                    }}
                                                    className="btn h-30px ml-0 fw-bold btn-sm d-flex align-items-center justify-content-center text-primary"
                                                    style={{ paddingLeft: 0 }}
                                                >
                                                    Download
                                                </button>
                                            )}
                                            <Button
                                                variant="primary"
                                                size="sm"
                                                disabled={!document.s3_link}
                                                onClick={() => handleViewButtonPDFClick(document[links[1]])}
                                            >
                                                View PDF
                                            </Button>
                                            {isInternational && (
                                                <div>{trackedDocuments.some(trackedDocument => trackedDocument.id === document.id && trackedDocument.track === 1) ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Remove from My Items</Tooltip>}
                                                    >
                                                        {({ ref, ...triggerHandler }) => (
                                                            <button
                                                                {...triggerHandler}
                                                                className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                                onClick={() => handleTrackInDocument(document[links[1]])}
                                                            >
                                                                <i ref={ref} className="bi bi-bookmark-fill fs-1 ms-6" style={{ color: '#007bff' }}></i>
                                                            </button>
                                                        )}
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id="button-tooltip-2">Add to My Items</Tooltip>}
                                                    >
                                                        {({ ref, ...triggerHandler }) => (
                                                            <button
                                                                {...triggerHandler}
                                                                className="btn h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                                onClick={() => handleTrackInDocument(document[links[1]])}
                                                            >
                                                                <i ref={ref} className="bi bi-bookmark fs-1 ms-6" style={{ color: '#008000' }}></i>
                                                            </button>
                                                        )}
                                                    </OverlayTrigger>
                                                )}</div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="card-body card-scroll pt-0 mt-n3">
                                        {secondaryRow && renderRow(document, secondaryRow, '#AA4A44', '#c5736d')}
                                        {thirdRow && renderRow(document, thirdRow, '#014f86', '#0077b6')}
                                        {bottomRow && renderRow(document, bottomRow, '#707070', '#505050')}
                                    </div>
                                    {document[summaryRow] !== null && document[summaryRow] !== '' && (
                                        <div className="d-flex align-items-center px-9 mt-n8">
                                            <h5 className="card-title pb-7 text-gray-600" style={{ fontWeight: 'normal' }}>
                                                {document[summaryRow]}
                                            </h5>
                                        </div>

                                    )}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DocumentListWrapper;
