import React, { useState, useRef, useEffect } from 'react';
import OverlayModal from '../../components/OverlayModal';
import UploadDocument from './UploadDocument';
import { KTIcon } from '../../../_metronic/helpers';

function MyResearchButtons({
  selectedButton,
  setSelectedButton,
  selectedOption,
  setSelectedOption,
  additionalInformation,
  askQuestion,
  clearQuestions
}) {

  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const debounceTimeout = useRef(null);
  const [fileLoc, setFileLoc] = useState('0');
  const [fileNames, setFileNames] = useState({ '0': '', '1': '', '2': '' }); // Store filenames for Compare Documents

  const buttonInformation = {
    Summarize: [0, 1],
    Translate: [0, 1],
    Impact: [0, 1],
    Action: [0, 1],
    FAQ: [0, 1],
    'Compare Documents': [],
  };

  const subButtons = ['Text', 'Document', 'Audio', 'Video', 'Link'];

  const handleClearResponses = () => {
    clearQuestions(); // Call the provided clearQuestions function
    setFileNames({ '0': '', '1': '', '2': '' }); // Reset file names
    setDocumentUploaded(false); // Reset document uploaded status
    additionalInformation.current = {
      text: '',
      document: {
        0: '',
        1: '',
        2: '',
      },
      url: '',
    };
  };

  const filteredSubButtons = buttonInformation[selectedButton].map(
    (index) => subButtons[index]
  );

  const handleButtonClick = (btVal) => {
    setSelectedButton(btVal);
    if (buttonInformation[btVal].length > 0) {
      setSelectedOption(subButtons[buttonInformation[btVal][0]]);
    } else {
      setSelectedOption('');
    }

    // Reset additional information and file names when button is clicked
    additionalInformation.current = {
      text: '',
      document: {
        0: '',
        1: '',
        2: '',
      },
      url: '',
    };
    setFileNames({ '0': '', '1': '', '2': '' });
  };

  const handleOptionChange = (newOption) => {
    setSelectedOption(newOption);

    // Clear additional information and filenames when option changes
    additionalInformation.current = {
      text: '',
      document: {
        0: '',
        1: '',
        2: '',
      },
      url: '',
    };
    setFileNames({ '0': '', '1': '', '2': '' });
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500);

    return () => {
      clearTimeout(debounceTimeout.current);
    };
  }, [inputValue]);

  useEffect(() => {
    if (selectedOption && debouncedValue !== '') {
      if (['Audio', 'Video', 'Link'].includes(selectedOption)) {
        additionalInformation.current['url'] = debouncedValue;
      } else if (selectedOption === 'Text') {
        additionalInformation.current['text'] = debouncedValue;
      }
    }
  }, [debouncedValue, selectedOption, additionalInformation]);

  const handleFileUpload = (file, filename) => {
    if (file) {
      additionalInformation.current['document'][filename] = file;
      setFileNames((prevNames) => ({
        ...prevNames,
        [filename]: file.name, // Store the file name
      }));
      setDocumentUploaded(true);
    } else {
      setDocumentUploaded(false);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-start flex-wrap mb-5 ms-0">
        {Object.keys(buttonInformation).map((btVal) => (
          <div key={btVal} className="me-2">
            <button
              className={`btn btn-sm mt-2 ${
                selectedButton === btVal ? 'btn-primary' : 'btn-outline-primary'
              }`}
              style={{
                border: selectedButton === btVal ? '2px solid #0d6efd' : '2px dashed #ced4da',
                color: selectedButton === btVal ? '#fff' : '#0d6efd',
                backgroundColor: selectedButton === btVal ? '#0d6efd' : 'transparent',
              }}
              onClick={() => handleButtonClick(btVal)}
            >
              {btVal}
            </button>
          </div>
        ))}
        <div className="ms-auto">  {/* This will push the "Clear" button to the far right */}
          <button
            className="btn btn-sm mt-2 btn-secondary"
            onClick={handleClearResponses}
          >
            Clear Responses
          </button>
        </div>
      </div>

      {filteredSubButtons.length > 0 && (
        <div className="d-flex justify-content-start flex-wrap radio-button-list align-items-center mb-5 ms-0">
          {filteredSubButtons.map((option, index) => (
            <label key={index} style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
              <input
                type="radio"
                value={option}
                checked={selectedOption === option}
                onChange={() => handleOptionChange(option)}
                style={{ marginRight: '5px' }}
              />
              {option}
            </label>
          ))}
        </div>
      )}

      <div className="mb-5">
        {selectedOption === 'Document' && (
          <div className="d-flex align-items-center mt-2">
            <button
              className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
              onClick={() => {
                setShowModal(true);
                setFileLoc('0');
              }}
                style={{ width: '200px' , height:'40px'}} // Set fixed width for the button

            >
               <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Upload Document

            </button>
            {fileNames['0'] && (
              <p className="ms-3 mt-0 mb-0 text-center">{fileNames['0']}</p>
            )}
          </div>
        )}

        {selectedButton === 'Compare Documents' && (
          <div className="d-flex flex-column align-items-start mt-0">
            <div className="d-flex align-items-center mt-2">
              <button
                className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                onClick={() => {
                  setShowModal(true);
                  setFileLoc('1');
                }}
                style={{ width: '200px' , height:'40px'}} // Set fixed width for the button
              >
                 <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Upload Document 1

              </button>
              {fileNames['1'] && (
                <p className="ms-3 mt-0 mb-0 text-center">{fileNames['1']}</p>
              )}
            </div>

            <div className="d-flex align-items-center mt-2">
              <button
                className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                onClick={() => {
                  setShowModal(true);
                  setFileLoc('2');
                }}
                style={{ width: '200px' , height:'40px'}} // Set fixed width for the button
              >
                 <KTIcon iconName='add-files' iconType="solid" className='fs-2' /> Upload Document 2

              </button>
              {fileNames['2'] && (
                <p className="ms-3 mt-0 mb-0 text-center">{fileNames['2']}</p>
              )}
            </div>
          </div>
        )}

        {['Audio', 'Video', 'Link'].includes(selectedOption) && (
          <>
            <div className="mb-2">
              <label>Provide {selectedOption}:</label>
            </div>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <input
                  type="text"
                  value={inputValue}
                  placeholder={`Enter ${selectedOption} URL`}
                  onChange={handleInputChange}
                  className="form-control mt-2"
                />
              </div>
              {(inputValue.trim() !== '' || documentUploaded) && (
                <button
                  className="btn btn-primary mt-3 ms-2 align-items-center"
                  onClick={askQuestion}
                >
                  Submit
                </button>
              )}
            </div>
          </>
        )}
      </div>

      <OverlayModal show={showModal} onClose={() => setShowModal(false)}>
        <UploadDocument additionalInformation={additionalInformation} handleFileUpload={handleFileUpload} filename={fileLoc} setShowModal={setShowModal} />
      </OverlayModal>
    </div>
  );
}

export default MyResearchButtons;
