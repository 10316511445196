// UploadSuccessModal.tsx
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

const UploadSuccessModal = ({ isOpen, onRequestClose, message }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(true);


    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            if (message !== '') {
                setIsLoading(false);
                setIsSuccess(message === 'Document uploaded successfully!');
            }
        }
        return () => {
            setIsSuccess(true);
            setIsLoading(true);
        };
    }, [isOpen, message]);

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Upload Success Modal"
            style={{
                overlay: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                },
                content: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: isLoading ? '350px' : '420px',
                    height: isLoading ? '100px' : '250px',
                    position: 'relative',
                    top: 'auto',
                    left: 'auto',
                    right: 'auto',
                    bottom: 'auto',
                    margin: '0 auto',
                    marginLeft: '625px',
                    borderRadius: '20px',
                    padding: '30px',
                    backgroundColor: '#ffffff',
                    fontFamily: '"Arial", sans-serif'
                }
            }}
        >
            {isLoading ? (
                <h2 className="mt-4 text-center" style={{ fontSize: '1.7em' }}>
                    Uploading document...
                </h2>
            ) : (
                <>
                    <i className={isSuccess ? "fas fa-check-circle text-success" : "fas fa-times-circle text-danger"} style={{ fontSize: '4.7em' }}></i>
                    <h2 className="mt-7 text-center" style={{ fontSize: '1.7em' }}>
                        {message.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </h2>
                    <button
                        className={`btn ${isSuccess ? "btn-success" : "btn-danger"} btn-md mt-6`}
                        data-kt-organizations-modal-action='close'
                        onClick={onRequestClose}
                        style={{ cursor: 'pointer', padding: '10px', width: '150px', fontSize: '1.1em' }}
                    >
                        Close
                    </button>
                </>
            )}
        </Modal>
    );
};

export default UploadSuccessModal;