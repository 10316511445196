import React, { useState, useEffect, forwardRef } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import FolderSuccessModal from '../Modals/FolderSuccessModal';

interface MyDocumentsTableRowProps {
    uploadedFile: {
        id: number,
        type: string,
        folder: string,
        parent_id: number | null,
        name: string,
        description: string,
        s3_link: string,
        create_date: Date,
        doc_name: string,
        is_genai_available: number,
        files: {
            id: number,
            type: string,
            folder: string,
            parent_id: number | null,
            name: string,
            description: string,
            s3_link: string,
            create_date: Date,
            doc_name: string,
            is_genai_available: number
        }[]
    };
    navigate: (path: string, options?: object) => void;
    editingFile: any;
    setEditingFile: (file: any) => void;
    setName: (name: string) => void;
    setDescription: (description: string) => void;
    name: string;
    description: string;
    updateDocument: (id: number, name: string, description: string, type: string, parent: number | null) => Promise<void>;
    fetchFiles: () => void;
    setDeleteConfirmation: (value: boolean) => void;
    setDocumentToDelete: (file: any) => void;
    handleSelectionChange: (id: number, isSelected: boolean) => void;
    selectedFiles: number[];
    draggableProps?: any;
    dragHandleProps?: any;
    ref?: React.Ref<HTMLTableRowElement>;
    toggleFolder?: (id: any, isReverse: boolean) => void;
    selectedFolder: { [key: string]: number | null } | null;
    prevFolder: { [key: string]: number | null } | null;
    toggleMenu: (fileId: number) => void;
    openDropdown: number;
    setShowMoveModal: (value: boolean) => void;
    setMoveFile: (file: any) => void;
}

const MyDocumentsTableRow = forwardRef<HTMLTableRowElement, MyDocumentsTableRowProps>(
    (
        {
            uploadedFile,
            navigate,
            editingFile,
            setEditingFile,
            setName,
            setDescription,
            name,
            description,
            updateDocument,
            fetchFiles,
            setDeleteConfirmation,
            setDocumentToDelete,
            handleSelectionChange,
            selectedFiles,
            draggableProps,
            dragHandleProps,
            toggleFolder,
            selectedFolder,
            prevFolder,
            toggleMenu,
            openDropdown,
            setShowMoveModal,
            setMoveFile
        },
        ref
    ) => {
        const [isChecked, setIsChecked] = useState(selectedFiles.includes(uploadedFile.id));
        const isFolder = uploadedFile.type === 'folder';
        const isRootFolder = uploadedFile.folder === null;
        const [showModal, setShowModal] = useState(false);

        useEffect(() => {
            setIsChecked(selectedFiles.includes(uploadedFile.id));

            // Automatically expand the root folder if applicable
            if (isRootFolder && toggleFolder) {
                toggleFolder(uploadedFile.id, false);
            }
        }, [selectedFiles, uploadedFile.id, isRootFolder, toggleFolder]);

        return (
            <>
                <tr
                    ref={ref}
                    {...draggableProps}
                    {...dragHandleProps}
                    style={{
                        cursor: 'pointer',
                        ...(draggableProps?.style || {}),
                    }}
                    onClick={() => {
                        if (isFolder && toggleFolder) {

                            toggleFolder({ [uploadedFile.name]: uploadedFile.id }, false);
                        } else if (!editingFile) {
                            navigate(`/apps/lexim-cabinet/${uploadedFile.name}`, {
                                state: { uploadedFile, selectedFolder, prevFolder },
                            });
                        }
                    }}
                >
                    {!isFolder ? (
                        <td style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation(); }}>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isChecked}
                                    style={{ border: '1px solid #000000', marginLeft: '7px', cursor: 'pointer' }}
                                    onClick={(e) => { e.stopPropagation(); }}
                                    onChange={(e) => {
                                        handleSelectionChange(uploadedFile.id, e.target.checked);
                                        setIsChecked(e.target.checked);
                                    }}
                                />
                            </div>
                        </td>
                    ) : (
                        <td></td>
                    )}

                    <td className={`text-start ${isFolder && editingFile !== uploadedFile && 'fs-4'}`} {...(isFolder ? { colSpan: 2 } : {})} >
                        {editingFile === uploadedFile ? (
                            <div className="p-2">
                                <textarea
                                    rows={isFolder ? 1 : 2}
                                    maxLength={500}
                                    value={isFolder ? name : description}
                                    onChange={(e) => isFolder ? setName(e.target.value) : setDescription(e.target.value)}
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ width: '100%', padding: '10px', resize: 'none' }}
                                />
                                <div className="text-end">{isFolder ? `${name.length}/100` : `${description.length}/500`}</div>
                            </div>
                        ) : isFolder ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <KTIcon iconName="folder" className="fs-1 duotone text-primary me-2" />
                                <span>{uploadedFile.name}</span>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                {uploadedFile.is_genai_available === 1 && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="genai-tooltip">AI features are available</Tooltip>}
                                    >
                                        <i className="bi bi-patch-check-fill fs-1 fw-bold text-success me-3"></i>
                                    </OverlayTrigger>
                                )}
                                {uploadedFile.doc_name}
                            </div>
                        )}
                    </td>

                    {!isFolder && (
                        <td className={`text-start`} >
                            {editingFile === uploadedFile ? (
                                <div className="p-2">
                                    <textarea
                                        rows={2}
                                        maxLength={100}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        onClick={(e) => e.stopPropagation()}
                                        style={{ width: '100%', padding: '10px', resize: 'none' }}
                                    />
                                    <div className="text-end">{name.length}/100</div>
                                </div>
                            ) : (
                                <div>
                                    <span>{uploadedFile.name}</span>
                                </div>
                            )}
                        </td>
                    )}

                    <td className="text-end">
                        {new Date(uploadedFile.create_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                    </td>

                    <td className="text-end ps-5"
                        onClick={(e) => e.stopPropagation()}
                        style={{ cursor: 'default' }} >
                        <div className="dropdown">
                            {/* Dropdown toggle button */}
                            <button
                                className="btn btn-light btn-active-light-primary btn-sm dropdown-toggle"
                                type="button"
                                id={`dropdownMenuButton-${uploadedFile.id}`}
                                data-bs-toggle="dropdown"
                                aria-expanded={openDropdown === uploadedFile.id ? 'true' : 'false'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleMenu(uploadedFile.id);
                                }}
                            >
                                Actions
                            </button>

                            {/* Dropdown menu */}
                            <ul
                                className={`dropdown-menu dropdown-menu-end`}
                                aria-labelledby={`dropdownMenuButton-${uploadedFile.id}`}
                                style={{ cursor: 'pointer' }}
                            >
                                {/* Save option (only visible when editing) */}
                                {editingFile === uploadedFile && (
                                    <li>
                                        <button
                                            className="dropdown-item fw-bold d-flex align-items-center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                updateDocument(uploadedFile.id, name, description, uploadedFile.type, uploadedFile.parent_id).then(() => {
                                                    fetchFiles();
                                                    setEditingFile(null);
                                                });
                                            }}
                                        >
                                            <i className="fas fa-check fs-5 text-success ms-4 me-2"></i>
                                            Save Edit
                                        </button>
                                    </li>
                                )}

                                {uploadedFile.name !== "Recently Deleted" && (
                                    <>
                                        <li>
                                            <button
                                                className="dropdown-item fw-bold d-flex align-items-center"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (editingFile === uploadedFile) {
                                                        setEditingFile(null);
                                                    } else {
                                                        setEditingFile(uploadedFile);
                                                        setName(uploadedFile.name);
                                                        setDescription(uploadedFile.doc_name);
                                                    }
                                                }}
                                            >
                                                {editingFile === uploadedFile ? (
                                                    <>
                                                        <i className="fas fa-times fs-5 text-danger ms-4 me-3"></i>
                                                        {"Cancel Edit"}
                                                    </>
                                                ) : (
                                                    <>
                                                        <KTIcon iconName="pencil" className="fs-5 duotone text-primary ms-3 me-2" />
                                                        {"Edit"}
                                                    </>
                                                )}
                                            </button>
                                        </li>

                                        <li>
                                            <button
                                                className="dropdown-item fw-bold d-flex align-items-center"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setMoveFile(uploadedFile);
                                                    setShowMoveModal(true);
                                                }}
                                            >
                                                <KTIcon iconName="file-right" className="fs-5 duotone text-primary ms-3 me-2" />
                                                Move
                                            </button>
                                        </li>
                                    </>
                                )}

                                {/* Delete option */}

                                <li>
                                    <button
                                        className="dropdown-item fw-bold d-flex align-items-center me-3"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (uploadedFile.files && uploadedFile.files.length > 0) {
                                                setShowModal(true);
                                            }
                                            else {
                                                setDeleteConfirmation(true);
                                                setDocumentToDelete(uploadedFile);
                                            }

                                        }}
                                    >
                                        <KTIcon iconName="trash" className="fs-5 duotone text-danger ms-3 me-2" />
                                        Delete
                                    </button>
                                </li>

                            </ul>
                        </div>
                    </td>
                </tr>
                <FolderSuccessModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    modalMessage="Folder still contains files or subfolders. Please empty contents of folder before deleting."
                />
            </>
        );
    }
);

export default MyDocumentsTableRow;
