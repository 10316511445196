import React from 'react';
import DocDiffTableHeader from './DocDiffTableHeader';
import DocDiffTableRow from './DocDiffTableRow';
import HourGlass from '../../Loading/HourGlassSpinner/HourGlass';

const DocDiffTable = ({ diffFiles, uploadedFiles, setDiffDeleteConfirmation, setDiffToDelete, isLoading }) => {

    return (
        isLoading ? <HourGlass /> :
            <div className='table-responsive px-5 mb-5'>
                <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                    style={{
                        '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                        '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                        'tableLayout': 'fixed'
                    }}>
                    <DocDiffTableHeader />

                    <tbody>
                        {diffFiles.length === 0 ? (
                            <tr>
                                <td
                                    colSpan={6}
                                    className='text-dark fw-semibold fs-5'
                                    style={{ textAlign: 'center' }}
                                >
                                    No records found
                                </td>
                            </tr>
                        ) : (
                            diffFiles
                                .map((diffFile, index) => (
                                    <DocDiffTableRow diffFile={diffFile} uploadedFiles={uploadedFiles} setDiffDeleteConfirmation={setDiffDeleteConfirmation} setDiffToDelete={setDiffToDelete} />
                                ))
                        )}
                    </tbody>
                </table>
            </div>
    );
};

export default DocDiffTable;

