import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { marked } from 'marked';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../modules/auth';
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap';
const ImpactViewer = ({ iddocument, name }) => {
    const [textData, setTextData] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const impactDataRef = useRef();
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`/reg/v1/document/mydocument/impact_link/${iddocument}`);
                if (response.headers['content-type'] === 'text/plain') {
                    const formattedText = marked(response.data);
                    setTextData(formattedText);
                    setAlert({ message: '', type: '' });
                } else {
                    const url = response.data.url;


                    if (url) {  // Check if url is not null
                        const textResponse = await axios.get(url);
                        if (response.headers['content-type'] === 'application/json') {
                            const formattedText = textResponse.data
                            setTextData(formattedText);
                            setAlert({ message: '', type: '' });
                        }
                    } else {
                        setAlert({ message: 'Impact Analysis for this document is still being generated. Please check back later!', type: 'info' });  // Set textData to 'No file found' if url is null
                    }

                }

            } catch (error) {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            logout();
                            navigate('/auth/login');
                            break;
                        case 400:
                            setAlert({ message: 'Impact Analysis for this document is still being generated. Please check back later!', type: 'info' });
                            break;
                        default:
                            setAlert({ message: 'An error occurred', type: 'danger' });
                    }
                } else {
                    setAlert({ message: 'Impact Analysis for this document is still being generated. Please check back later!', type: 'danger' });
                }
            }
            setIsLoading(false);
        };

        fetchData();
    }, [iddocument, logout, navigate]);
    function generateTable(data) {
        const lines = data.split('\n');
        let html = '<table>';

        lines.forEach(line => {
            const parts = line.split('  ').filter(part => part.trim() !== '');
            if (parts.length === 2) {
                html += '<tr><td style="padding-right: 10px;">' + parts[0].trim().replace(/\./g, '') + '</td><td>' + parts[1].trim() + '</td></tr>';
            }
        });

        html += '</table>';
        return html;
    }

    const renderData = (data, level = 0) => {
        return Object.entries(data).map(([key, value], index) => {
            if (typeof value === 'string' && value.includes('..........')) {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `• ${key}` : key}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title mb-4 fs-6 pb-2 text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12} dangerouslySetInnerHTML={{ __html: generateTable(value) }}></Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            }
            else if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title fw-bold text-uppercase  text-justify ${level > 0 ? ' fs-6 bg-light-secondary text-black' : 'fs-5 bg-light-primary text-primary'}`}>
                                <Col md={12}>{level > 0 ? ` ${key}` : key}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title mb-4 fs-6 pb-2 text-justify ${level > 0 ? 'ps-6' : ''}`}>
                                <Col md={12}>{renderData(value, level + 1)}</Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment key={index}>
                        <div className={`card-title text-justify`}>
                            <span className="text-uppercase">{level > 0 ? ` ${key}.` : key}</span>
                            <span className={`fs-6 mb-4 text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                {value}
                            </span>
                        </div>
                    </React.Fragment>
                );
            }
        });
    }

    const handleDownloadImpact = () => {
        let textString = impactDataRef.current.innerText;

        // Split the text into lines
        let lines = textString.split('\n');

        // Process each line
        lines = lines.map(line => {
            // Remove ** from the line
            line = line.replace(/\*\*/g, '');

            // Trim the line
            line = line.trim();

            // Return the processed line
            return line;
        });

        // Join the lines back together, adding extra line breaks between them
        textString = lines.join('\n\n');

        // Add some space at the top of the file
        textString = '\n\n\n' + textString;

        const blob = new Blob([textString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const baseName = name.replace(/\.[^/.]+$/, "");
        link.download = `${baseName} impact.txt`;
        link.click();
    };
    return (
        <>
            {isLoading ? (
                <div className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : textData ? (

                <Container className=" w-100  text-wrap   vh-100"
                    style={{ overflowY: 'auto', overflowX: 'hidden', whiteSpace: 'pre-wrap', marginLeft: 0 }}>
                    <div className="d-flex justify-content-end mb-4">
                        <Button onClick={handleDownloadImpact}><i className="bi bi-download fs-4 me-2"></i>Download</Button>
                    </div>



                    <div ref={impactDataRef}>
                        {renderData(textData)}
                    </div>
                </Container>
            ) : (
                <div className="d-flex flex-column align-items-center w-100 mt-5 text-wrap mx-auto vh-100">
                    <div className={`alert alert-${alert.type} alert-dismissible fade show mt-3`} role="alert">
                        {alert.message}

                    </div>
                    {alert.type === 'info' && (
                        <img src="/media/illustrations/dozzy-1/2.png" alt="Background" style={{ maxWidth: '80%' }} />
                    )}                </div>
            )}

        </>
    );
};

export default ImpactViewer;