import React, { useEffect, useState } from 'react';

function UploadDocument({ 
    additionalInformation,
    handleFileUpload, 
    filename, 
    setShowModal }) {
  // const [internalUpload, setInternalUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(''); // State to store validation errors

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]; // Correct way to access the files
    const maxSizeInMB = 20; // Max file size in MB

    if (selectedFile) {
      // Check file type
      const allowedTypes = ['application/pdf', 'text/plain'];
      if (!allowedTypes.includes(selectedFile.type)) {
        setError('Only .txt and .pdf files are allowed.');
        setFile(null);
        return;
      }

      // Check file size
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      if (selectedFile.size > maxSizeInBytes) {
        setError(`File size should not exceed ${maxSizeInMB}MB.`);
        setFile(null);
        return;
      }

      // If valid, clear errors and set the file
      setError('');
      setFile(selectedFile);
    }
  };

  useEffect(()=>{
    if (!additionalInformation.current['document'][filename]) setFile(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[additionalInformation])
  
  const handleSubmit = () => {
    if (file) {
      handleFileUpload(file, filename); // Pass file and filename to the parent component
    }
    setShowModal(false);
  };

  const fileInputRef = React.createRef();

  const triggerFileInput = () => {
    fileInputRef.current.click(); // Trigger the file input dialog when the button is clicked
  };

  return (
    <div>
      <div className='row'>
        <label htmlFor='document' className="col-3">
          <h4 className="required mt-3">Browse:</h4>
          <span>*File size &le; 20 MB, </span>
          
        </label>

        {/* Hidden file input */}
        <input
          type="file"
          onChange={handleFileChange} // Correct file input handler
          className="form-control mt-3 col-7"
          accept=".pdf,.txt" // Restrict file selection in the browser
          ref={fileInputRef} // Reference to the input for triggering it programmatically
          style={{ display: 'none' }} // Hide the input
        />

        {/* Button to trigger file input */}
        <button
          className="btn btn-primary mt-3 col-2" // Adjusted col size to fit within the row
          style={{ height: '40px', width:'120px', paddingLeft: '20px', paddingRight: '20px' }} // Set specific width and padding
          onClick={triggerFileInput} // Open file input dialog when clicked
        >
          Browse
        </button>
        {error && <p className="text-danger mt-2">{error}</p>} {/* Display errors */}
        {file && (
          <div className="mt-3">
            <p>Selected file: {file.name}</p>
          </div>
        )}
      </div>
      
      

      <div className='mt-5'>
        <button
          className="btn btn-secondary col-3 offset-5"
          onClick={() => setShowModal(false)} // Close modal without submitting
        >
          Discard
        </button>
        <button
          className="btn btn-success ms-10 col-3"
          onClick={handleSubmit} // Submit file
        >
          Upload
        </button>
      </div>
    </div>
  );
}

export default UploadDocument;
