import React, { useState, useEffect, useRef } from 'react';
import { KTIcon } from '../../../../_metronic/helpers';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm'
import styled from 'styled-components';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HourGlass from '../../Loading/HourGlassSpinner/HourGlass';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const StyledMarkdown = styled(ReactMarkdown)`
&.markdown table {
    width: 100%;
    border-collapse: collapse;
}

&.markdown th, &.markdown td {
    border: 1px solid #ddd;
    padding: 8px;
}

&.markdown th {
    background-color: #f2f2f2;
    text-align: left;
}
`;

const CompareDocumentDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const title1 = useParams().id1;
    const title2 = useParams().id2;
    const filename1 = location?.state?.filename1 || '';
    const filename2 = location?.state?.filename2 || '';
    const diffFile = location?.state?.diffFile || {};
    const isCompleted = diffFile.is_completed;
    const s3_link = diffFile.s3_link;
    const [textData, setTextData] = useState('');
    const [loading, setLoading] = useState(false);
    const renderedDataRef = useRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (s3_link) {
                    const textResponse = await axios.get(s3_link);
                    const formattedText = textResponse.data
                    setTextData(formattedText);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        }
        fetchData();
    }, [isCompleted, s3_link]);

    function generateTable(data) {
        const lines = data.split('\n');
        let html = '<table style="width: 100%; border-collapse: collapse;">';

        lines.forEach(line => {
            const parts = line.split('  ').filter(part => part.trim() !== '');
            if (parts.length === 2) {
                html += '<tr><td style="border: 1px solid #ddd; padding: 8px; padding-right: 10px;">' + parts[0].trim().replace(/\./g, '') + '</td><td style="border: 1px solid #ddd; padding: 8px;">' + parts[1].trim() + '</td></tr>';
            }
        });

        html += '</table>';
        return html;
    }

    const renderData = (data, level = 0) => {
        return Object.entries(data).map(([key, value], index) => {
            console.log([key, value]);

            if (typeof value === 'string' && value.includes('..........')) {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `• ${key}` : key}hi</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title mb-4 fs-6 pb-2 text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12} dangerouslySetInnerHTML={{ __html: generateTable(value) }}></Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            }
            else if (typeof value === 'object' && value !== null) {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title  fw-bold bg-light-primary text-uppercase text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `• ${key}` : key}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title fs-6  text-justify ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12}>{renderData(value, level + 1)}</Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment key={index}>
                        {key && (
                            <Row className={`p-1 card-title  fw-bold text-uppercase  text-primary text-justify ${level > 0 ? 'ps-7 fs-6' : 'fs-5'}`}>
                                <Col md={12}>{level > 0 ? `\u23F5  ${key} ` : ' '}</Col>
                            </Row>
                        )}
                        {value && (
                            <Row className={`card-title fs-6 text-justify text-gray-800 ${level > 0 ? 'ps-7' : ''}`}>
                                <Col md={12}>

                                    <StyledMarkdown className="markdown" remarkPlugins={[gfm]} >{value.replace(/(\n)(?!\|)/g, '\n\n\n')}</StyledMarkdown>

                                </Col>
                            </Row>
                        )}
                    </React.Fragment>
                );
            }
        });
    }

    const handleDownload = () => {
        let textString = renderedDataRef.current.innerText;

        // Split the text into lines
        let lines = textString.split('\n');

        // Process each line
        lines = lines.map(line => {
            // Remove ** from the line
            line = line.replace(/\*\*/g, '');

            // Trim the line
            line = line.trim();

            // Return the processed line
            return line;
        });

        // Join the lines back together, adding extra line breaks between them
        textString = lines.join('\n\n');

        // Add some space at the top of the file
        textString = '\n\n\n' + textString;

        const blob = new Blob([textString], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${title1} ${title2} summary.txt`;
        link.click();
    };

    return (
        <>
            <div className="pb-2" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100%' }}>
                <div style={{ marginRight: '20px', width: '250px' }}>
                    <Link
                        to={`/apps/lexim-cabinet`}
                        state={{ activeTab: 'compareDocuments' }}
                        style={{
                            color: '#0d6efd',
                            textDecoration: 'none',
                            fontSize: '1.3em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onMouseEnter={(e) => e.target.style.color = '#0d6efd'}
                        onMouseLeave={(e) => e.target.style.color = '#0d6efd'}
                    >
                        <KTIcon iconName='arrow-left' className='fs-2 me-2' style={{ marginRight: '10px' }} />
                        Compare Documents List
                    </Link>
                </div>
            </div>
            <div className="card mt-3 mb-4">
                <div className="card-body d-flex align-items-center">
                    <div className="position-relative flex-wrap w-100">
                        <div className="d-flex align-items-center justify-content-between mb-1">
                            <h2 className="text-gray-800 fw-bold">Document Difference</h2>
                            {textData && (
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-download">Download</Tooltip>}
                                >
                                    <i className="bi bi-download fs-1 ms-auto me-1 mt-n4 text-primary" style={{ cursor: 'pointer' }} onClick={handleDownload}></i>
                                </OverlayTrigger>
                            )}
                        </div>
                        <div className="d-flex align-items-center mb-1 text-wrap">
                            <h5 className="card-title text-gray-800" >First Document: <span className="text-gray-600" >{title1 && filename1 ? `${title1} (${filename1})` : "Not found"}</span></h5>
                        </div>
                        <div className="d-flex align-items-center text-wrap">
                            <h5 className="card-title text-gray-800  " >Second Document: <span className="text-gray-600">{title2 && filename2 ? `${title2} (${filename2})` : "Not found"}</span></h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-auto'>
                    {!isCompleted && (
                        <div className="d-flex flex-column align-items-center w-100 mt-5 text-wrap mx-auto vh-100">
                            <div className="alert alert-info mt-3" role="alert">
                                <i className="bi bi-info-circle fs-4 me-2"></i>
                                A comparison request for the two selected documents has been made and the system is working on it. Please check back later.
                            </div>
                            <div>
                                For more info, check compare requests{' '}
                                <span
                                    className="text-primary cursor-pointer"
                                    onClick={() => navigate('/apps/lexim-cabinet', { state: { activeTab: 'compareDocuments' } })}
                                >
                                    here
                                </span>.
                            </div>
                        </div>
                    )}
                    {loading && isCompleted ? (
                        <HourGlass />
                    ) : textData ? (
                        <Container
                            className="w-100 text-wrap mx-auto vh-100 mb-4 p-4"
                            style={{
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                whiteSpace: 'pre-wrap',
                            }}
                        >
                            <div className="mb-4 pt-3 pb-3 ps-1 bg-light-primary fw-bold text-primary fs-4">
                                DOCUMENT DIFFERENCE
                            </div>
                            <div ref={renderedDataRef} className="p-1">
                                {renderData(textData)}
                            </div>
                        </Container>
                    ) : null}
                </div>
            </div>
        </>
    )
}
export default CompareDocumentDetails;